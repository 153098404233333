import React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {
    $phoneInput,
    phoneInputHandler,
} from '../../../features/sign-in-form/model/model'
import { useUnit } from 'effector-react'

const NumberInput = () => {
    const value = useUnit($phoneInput)

    return (
        <PhoneInput
            placeholder="Enter phone number"
            value={value}
            onChange={(e: any) => phoneInputHandler(e)}
            international={true}
        />
    )
}

export default NumberInput
