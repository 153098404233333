import { createEffect, createEvent, createStore, sample } from 'effector'
import { historyItemDeleteQuery, historyQuery } from './query'

export const historyFx = createEffect(historyQuery)
export const $history = createStore<any>([]).on(
    historyFx.doneData,
    (_store, res) => res
)
export const historyEvent = createEvent()

sample({
    fn: (clk: any) => clk,
    target: historyEvent,
    clock: historyFx.doneData,
})

//

export const deleteHistoryFx = createEffect(historyItemDeleteQuery)
export const $deleteHistory = createStore<any>(null)
export const deleteHistoryEvent = createEvent()

sample({
    fn: (clk: any) => clk,
    target: deleteHistoryEvent,
    clock: deleteHistoryFx.doneData,
})
