import $api from '../../http'

//ts-ignore
export const historyQuery = async () => {
    try {
        const res = await $api.api.textsControllerGetTexts({
            limit: 100,

            // @ts-ignore
            'filter.request.operation': 'UNIQUE',
        })
        return res.data.data.reverse()
    } catch (error) {
        console.log(error)
    }
}

export const historyItemDeleteQuery = async (requestId: string) => { 
    try {
        const res = await $api.api.textsControllerDelete(requestId)
        return res.data
    } catch (error) {
        console.log(error)
    }
}