let monthList = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Augest',
    'September',
    'November',
    'December',
]

let seMothns = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December',
]

export function convertTimeToHumanReadable(timeString: string, lang: string) {
    const date = new Date(timeString)

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const humanReadableTime = `${day} ${
        lang == 'en' ? monthList[month - 1] : seMothns[month - 1]
    } ${year} ${hours}:${minutes <= 10 ? `0${minutes}` : minutes}`

    return humanReadableTime
}
