import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'
import { ReactComponent as PlusIcon } from '../../pages/GenerationBlock/pages/ShadowGPT/assets/Plus.svg'
import {
    $activeChat,
    $chatList,
    activeChatEvent,
    chatListFx,
    chatListHandler,
    createChatFx,
    deleteChatFx,
    pinMessageFx,
    renameChatFx,
    upPinMessageFx,
} from '../../features/chat/model'
import { useUnit } from 'effector-react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { $user } from '../../features/app/model'
import { useTranslation } from 'react-i18next'

export const ShadowChatList: React.FC = () => {
    const user = useUnit($user)
    const [chatList, activeChat, getChats] = useUnit([
        $chatList,
        $activeChat,
        chatListFx,
    ])
    const deleteChatFn = useUnit(deleteChatFx)
    const [pinMessage, upPinMessage] = useUnit([pinMessageFx, upPinMessageFx])
    const [rename, renameLoading] = useUnit([
        renameChatFx,
        renameChatFx.pending,
    ])

    React.useEffect(() => {
        if (Object.keys(user).length) chatListFx()
    }, [user])

    const createCreate = () => {
        createChatFx(`${t('chat.chatName')} #${chatList.length + 1}`).then(
            (res: any) => {
                let pinedChats = chatList.filter((chat: any) => chat.isPined)
                let notPinedChats = chatList.filter(
                    (chat: any) => !chat.isPined
                )
                activeChatEvent(res)
                chatListHandler([...pinedChats, res, ...notPinedChats])
            }
        )
    }

    const deleteChat = (chatId: string) => {
        deleteChatFn(chatId).then((res) => {
            const filtered = chatList.filter((el: any) => el.id !== chatId)
            chatListHandler(filtered)
            if (filtered.length > 0) activeChatEvent(filtered[0])
            else activeChatEvent({})
        })
    }

    const pinChatHandler = (chatId: string) => {
        pinMessage(chatId)
        getChats()
    }

    const renameChat = (chatId: string, val: string) => {
        if (val.length > 2) {
            rename({ chatId, val })
            chatList.find((el: any) => el.id === chatId).name = val
        } else {
        }
    }

    const unPinChatHandler = (chatId: string) => {
        upPinMessage(chatId)
        getChats()
    }

    const RenderCard = (el: any) => {
        const [isShowDots, setIsShowDots] = useState<boolean>(false)
        const [isShow, setIsShow] = useState<boolean>(false)
        const [renameVal, setRenameVal] = useState<string>(el.el.name)
        const [isShowRename, setIsShowRename] = useState<boolean>(false)
        const scrollRef = useRef(null)

        const ref = useOnclickOutside(() => {
            setIsShow(false)
            setIsShowDots(false)
        })

        const refRename = useOnclickOutside(() => {
            if (renameVal.length > 3) {
                renameChat(el.el.id, renameVal)
                setIsShowRename(false)
            } else setIsShowRename(false)
        })

        useEffect(() => {
            if (isShow)
                document.getElementById('1')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'start',
                })
        }, [isShow])

        return (
            <>
                {window.innerWidth > 768 ? (
                    <div style={{ position: 'relative', height: '32px' }}>
                        <div
                            key={el.el.id}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                activeChatEvent(el.el)
                                setIsShowDots(true)
                            }}
                            className={`${styles.ShadowChatList__chatButton} ${
                                activeChat.id === el.el.id &&
                                styles.ShadowChatList__chatButton_selected
                            }`}
                            onMouseEnter={() => setIsShowDots(true)}
                            onMouseLeave={() => setIsShowDots(false)}
                        >
                            {isShowRename && !renameLoading ? (
                                <input
                                    type="text"
                                    value={renameVal}
                                    onChange={(e) =>
                                        setRenameVal(e.target.value)
                                    }
                                    ref={refRename}
                                />
                            ) : (
                                el.el.name
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {el.el.isPined && (
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.85315 10.8537C5.94692 10.76 5.99979 10.6326 5.99979 10.5C5.99979 10.3674 5.94711 10.2402 5.85334 10.1464L5.84902 10.1422C5.75566 10.0512 5.63015 10 5.49979 10C5.36718 10 5.24 10.0527 5.14623 10.1464L2.64642 12.6463C2.55265 12.74 2.49979 12.8674 2.49979 13C2.49979 13.1326 2.55246 13.2598 2.64623 13.3536C2.74 13.4473 2.86718 13.5 2.99979 13.5C3.13239 13.5 3.25957 13.4473 3.35334 13.3536L5.85315 10.8537Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9.61356 1.28462C9.61356 1.28462 9.79887 1.20703 9.99978 1.20703C9.99978 1.20703 10.2007 1.20703 10.386 1.28462C10.386 1.28462 10.5713 1.36222 10.7096 1.50264L14.4944 5.28745C14.4944 5.28745 14.6375 5.42842 14.7151 5.61373C14.7151 5.61373 14.7927 5.79904 14.7927 5.99995C14.7927 5.99995 14.7927 6.20085 14.7151 6.38616C14.7151 6.38616 14.6375 6.57147 14.4971 6.70975L10.8434 10.3634C10.8434 10.3634 11.2031 11.3403 10.8806 12.4476C10.8806 12.4476 10.6317 13.3022 10.0182 14.1129C10.0182 14.1129 9.88693 14.2875 9.69485 14.3914C9.69485 14.3914 9.50278 14.4954 9.28485 14.5098C9.28485 14.5098 9.06693 14.5242 8.86285 14.4464C8.86285 14.4464 8.65876 14.3687 8.50873 14.216L1.77275 7.48002C1.77275 7.48002 1.62137 7.32994 1.54436 7.13117C1.54436 7.13117 1.46734 6.93241 1.47808 6.71952C1.47808 6.71952 1.48881 6.50663 1.58543 6.31662C1.58543 6.31662 1.68205 6.12661 1.84802 5.9923C1.84802 5.9923 3.15551 4.93574 4.67443 5.00357C4.67443 5.00357 5.21085 5.02752 5.62965 5.16297L9.28728 1.50536C9.28728 1.50536 9.42825 1.36222 9.61356 1.28462ZM9.99978 2.20703L6.10333 6.1035C5.95096 6.25587 5.71812 6.29348 5.52553 6.19684C5.52553 6.19684 5.18808 6.0275 4.62982 6.00257C4.62982 6.00257 3.48952 5.95165 2.47681 6.76987L9.21583 13.5089L9.21888 13.512C9.21888 13.512 9.72312 12.8456 9.92051 12.1679C9.92051 12.1679 10.1896 11.2442 9.80296 10.4743C9.70623 10.2817 9.74382 10.0488 9.89623 9.89639L13.79 6.00264L13.7927 5.99995L10.0025 2.20975L9.99978 2.20703Z"
                                            fill="#F8F8F8"
                                        />
                                    </svg>
                                )}
                                <div ref={ref}>
                                    <div
                                        className={styles.ShadowChatList__dots}
                                        style={isShowDots ? { opacity: 1 } : {}}
                                        onClick={() => setIsShow(!isShow)}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>{' '}
                                    <div
                                        id={isShow ? '1' : ''}
                                        className={
                                            isShow
                                                ? styles.ShadowChatList_dropdown +
                                                  ` ${styles.ShadowChatList_dropdown_show}`
                                                : styles.ShadowChatList_dropdown
                                        }
                                    >
                                        <div
                                            className={
                                                styles.ShadowChatList_dropdown_item
                                            }
                                            onClick={() => {
                                                if (!el.el.isPined) {
                                                    pinChatHandler(el.el.id)
                                                } else {
                                                    unPinChatHandler(el?.el.id)
                                                }
                                            }}
                                        >
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.85315 10.8537C5.94692 10.76 5.99979 10.6326 5.99979 10.5C5.99979 10.3674 5.94711 10.2402 5.85334 10.1464L5.84902 10.1422C5.75566 10.0512 5.63015 10 5.49979 10C5.36718 10 5.24 10.0527 5.14623 10.1464L2.64642 12.6463C2.55265 12.74 2.49979 12.8674 2.49979 13C2.49979 13.1326 2.55246 13.2598 2.64623 13.3536C2.74 13.4473 2.86718 13.5 2.99979 13.5C3.13239 13.5 3.25957 13.4473 3.35334 13.3536L5.85315 10.8537Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M9.61356 1.28462C9.61356 1.28462 9.79887 1.20703 9.99978 1.20703C9.99978 1.20703 10.2007 1.20703 10.386 1.28462C10.386 1.28462 10.5713 1.36222 10.7096 1.50264L14.4944 5.28745C14.4944 5.28745 14.6375 5.42842 14.7151 5.61373C14.7151 5.61373 14.7927 5.79904 14.7927 5.99995C14.7927 5.99995 14.7927 6.20085 14.7151 6.38616C14.7151 6.38616 14.6375 6.57147 14.4971 6.70975L10.8434 10.3634C10.8434 10.3634 11.2031 11.3403 10.8806 12.4476C10.8806 12.4476 10.6317 13.3022 10.0182 14.1129C10.0182 14.1129 9.88693 14.2875 9.69485 14.3914C9.69485 14.3914 9.50278 14.4954 9.28485 14.5098C9.28485 14.5098 9.06693 14.5242 8.86285 14.4464C8.86285 14.4464 8.65876 14.3687 8.50873 14.216L1.77275 7.48002C1.77275 7.48002 1.62137 7.32994 1.54436 7.13117C1.54436 7.13117 1.46734 6.93241 1.47808 6.71952C1.47808 6.71952 1.48881 6.50663 1.58543 6.31662C1.58543 6.31662 1.68205 6.12661 1.84802 5.9923C1.84802 5.9923 3.15551 4.93574 4.67443 5.00357C4.67443 5.00357 5.21085 5.02752 5.62965 5.16297L9.28728 1.50536C9.28728 1.50536 9.42825 1.36222 9.61356 1.28462ZM9.99978 2.20703L6.10333 6.1035C5.95096 6.25587 5.71812 6.29348 5.52553 6.19684C5.52553 6.19684 5.18808 6.0275 4.62982 6.00257C4.62982 6.00257 3.48952 5.95165 2.47681 6.76987L9.21583 13.5089L9.21888 13.512C9.21888 13.512 9.72312 12.8456 9.92051 12.1679C9.92051 12.1679 10.1896 11.2442 9.80296 10.4743C9.70623 10.2817 9.74382 10.0488 9.89623 9.89639L13.79 6.00264L13.7927 5.99995L10.0025 2.20975L9.99978 2.20703Z"
                                                    fill="#F8F8F8"
                                                />
                                            </svg>
                                            <p>
                                                {el.el.isPined
                                                    ? t('chat.unpin')
                                                    : t('chat.pin')}
                                            </p>
                                        </div>
                                        <div
                                            className={
                                                styles.ShadowChatList_dropdown_item
                                            }
                                            onClick={() => {
                                                setIsShowRename(true)
                                            }}
                                        >
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M13.9962 6.21081L6.35355 13.8535C6.25979 13.9473 6.13261 13.9999 6 13.9999H3C3 13.9999 2.58579 13.9999 2.29289 13.7071C2.29289 13.7071 2 13.4142 2 12.9999L2 10.2062C2 10.2062 1.99931 10.01 2.07451 9.82692C2.07451 9.82692 2.14971 9.64382 2.28932 9.50352L9.7902 2.00264C9.7902 2.00264 9.92847 1.86222 10.1138 1.78462C10.1138 1.78462 10.2991 1.70703 10.5 1.70703C10.5 1.70703 10.7009 1.70703 10.8862 1.78462C10.8862 1.78462 11.0715 1.86222 11.2125 2.00536L13.9973 4.79014C13.9973 4.79014 14.1377 4.92842 14.2153 5.11373C14.2153 5.11373 14.2929 5.29904 14.2929 5.49995C14.2929 5.49995 14.2929 5.70085 14.2153 5.88616C14.2153 5.88616 14.1385 6.06971 13.9973 6.20975L13.9962 6.21081ZM13.2929 5.49995L13.2902 5.49725L10.5 2.70703L10.4973 2.70975L3 10.207V12.9999H5.79289L13.2902 5.50264L13.2929 5.49995Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    d="M13.5 13H5.99999C5.72385 13 5.49999 13.2239 5.49999 13.5C5.49999 13.7761 5.72386 13.9999 6 13.9999L13.5 14C13.7761 14 14 13.7761 14 13.5C14 13.2239 13.7761 13 13.5 13Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    d="M11.6462 7.85329C11.74 7.94706 11.8674 8 12 8C12.1326 8 12.2598 7.94732 12.3535 7.85355C12.4473 7.75979 12.5 7.63261 12.5 7.5C12.5 7.36739 12.4473 7.24021 12.3535 7.14645L8.85361 3.64651C8.75978 3.55268 8.6326 3.5 8.49999 3.5C8.36739 3.5 8.24021 3.55268 8.14644 3.64645C8.05267 3.74021 7.99999 3.86739 7.99999 4C7.99999 4.13261 8.05267 4.25979 8.14644 4.35355L11.6462 7.85329Z"
                                                    fill="#F8F8F8"
                                                />
                                            </svg>

                                            <p>{t('chat.rename')}</p>
                                        </div>
                                        <div
                                            className={
                                                styles.ShadowChatList_dropdown_item
                                            }
                                            onClick={() => {
                                                deleteChat(el?.el.id)
                                            }}
                                        >
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M13.5 3H2.5C2.22386 3 2 3.22386 2 3.5C2 3.77614 2.22386 4 2.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    d="M6 6.5V10.5C6 10.7761 6.22386 11 6.5 11C6.77614 11 7 10.7761 7 10.5V6.5C7 6.22386 6.77614 6 6.5 6C6.22386 6 6 6.22386 6 6.5Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    d="M9 6.5V10.5C9 10.7761 9.22386 11 9.5 11C9.77614 11 10 10.7761 10 10.5V6.5C10 6.22386 9.77614 6 9.5 6C9.22386 6 9 6.22386 9 6.5Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    d="M4 13V3.5C4 3.22386 3.77614 3 3.5 3C3.22386 3 3 3.22386 3 3.5V13C3 13.4142 3.29289 13.7071 3.29289 13.7071C3.58579 14 4 14 4 14H12C12.4142 14 12.7071 13.7071 12.7071 13.7071C13 13.4142 13 13 13 13V3.5C13 3.22386 12.7761 3 12.5 3C12.2239 3 12 3.22386 12 3.5V13H4Z"
                                                    fill="#F8F8F8"
                                                />
                                                <path
                                                    d="M5.43934 1.43934C5 1.87868 5 2.5 5 2.5V3.5C5 3.77614 5.22386 4 5.5 4C5.77614 4 6 3.77614 6 3.5V2.5C6 2.29289 6.14645 2.14645 6.14645 2.14645C6.29289 2 6.5 2 6.5 2H9.5C9.70711 2 9.85355 2.14645 9.85355 2.14645C10 2.29289 10 2.5 10 2.5V3.5C10 3.77614 10.2239 4 10.5 4C10.7761 4 11 3.77614 11 3.5V2.5C11 1.87868 10.5607 1.43934 10.5607 1.43934C10.1213 1 9.5 1 9.5 1H6.5C5.87868 1 5.43934 1.43934 5.43934 1.43934Z"
                                                    fill="#F8F8F8"
                                                />
                                            </svg>

                                            <p>{t('chat.delete')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{ position: 'relative', height: '32px' }}>
                            <div
                                key={el.el.id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    activeChatEvent(el.el)
                                    setIsShowDots(true)
                                }}
                                className={`${
                                    styles.ShadowChatList__chatButton
                                } ${
                                    activeChat.id === el.el.id &&
                                    styles.ShadowChatList__chatButton_selected
                                }`}
                                onMouseEnter={() => setIsShowDots(true)}
                                onMouseLeave={() => setIsShowDots(false)}
                            >
                                {isShowRename && !renameLoading ? (
                                    <input
                                        type="text"
                                        value={renameVal}
                                        onChange={(e) =>
                                            setRenameVal(e.target.value)
                                        }
                                        ref={refRename}
                                    />
                                ) : (
                                    el.el.name
                                )}
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {el.el.isPined && (
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M5.85315 10.8537C5.94692 10.76 5.99979 10.6326 5.99979 10.5C5.99979 10.3674 5.94711 10.2402 5.85334 10.1464L5.84902 10.1422C5.75566 10.0512 5.63015 10 5.49979 10C5.36718 10 5.24 10.0527 5.14623 10.1464L2.64642 12.6463C2.55265 12.74 2.49979 12.8674 2.49979 13C2.49979 13.1326 2.55246 13.2598 2.64623 13.3536C2.74 13.4473 2.86718 13.5 2.99979 13.5C3.13239 13.5 3.25957 13.4473 3.35334 13.3536L5.85315 10.8537Z"
                                                fill="#F8F8F8"
                                            />
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M9.61356 1.28462C9.61356 1.28462 9.79887 1.20703 9.99978 1.20703C9.99978 1.20703 10.2007 1.20703 10.386 1.28462C10.386 1.28462 10.5713 1.36222 10.7096 1.50264L14.4944 5.28745C14.4944 5.28745 14.6375 5.42842 14.7151 5.61373C14.7151 5.61373 14.7927 5.79904 14.7927 5.99995C14.7927 5.99995 14.7927 6.20085 14.7151 6.38616C14.7151 6.38616 14.6375 6.57147 14.4971 6.70975L10.8434 10.3634C10.8434 10.3634 11.2031 11.3403 10.8806 12.4476C10.8806 12.4476 10.6317 13.3022 10.0182 14.1129C10.0182 14.1129 9.88693 14.2875 9.69485 14.3914C9.69485 14.3914 9.50278 14.4954 9.28485 14.5098C9.28485 14.5098 9.06693 14.5242 8.86285 14.4464C8.86285 14.4464 8.65876 14.3687 8.50873 14.216L1.77275 7.48002C1.77275 7.48002 1.62137 7.32994 1.54436 7.13117C1.54436 7.13117 1.46734 6.93241 1.47808 6.71952C1.47808 6.71952 1.48881 6.50663 1.58543 6.31662C1.58543 6.31662 1.68205 6.12661 1.84802 5.9923C1.84802 5.9923 3.15551 4.93574 4.67443 5.00357C4.67443 5.00357 5.21085 5.02752 5.62965 5.16297L9.28728 1.50536C9.28728 1.50536 9.42825 1.36222 9.61356 1.28462ZM9.99978 2.20703L6.10333 6.1035C5.95096 6.25587 5.71812 6.29348 5.52553 6.19684C5.52553 6.19684 5.18808 6.0275 4.62982 6.00257C4.62982 6.00257 3.48952 5.95165 2.47681 6.76987L9.21583 13.5089L9.21888 13.512C9.21888 13.512 9.72312 12.8456 9.92051 12.1679C9.92051 12.1679 10.1896 11.2442 9.80296 10.4743C9.70623 10.2817 9.74382 10.0488 9.89623 9.89639L13.79 6.00264L13.7927 5.99995L10.0025 2.20975L9.99978 2.20703Z"
                                                fill="#F8F8F8"
                                            />
                                        </svg>
                                    )}
                                </div>
                                <div
                                    className={styles.ShadowChatList__dots}
                                    style={isShowDots ? { opacity: 1 } : {}}
                                    onClick={(e: any) => {
                                        setIsShow(!isShow)
                                    }}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                        <>
                            <div
                                // ref={isShow ? scrollRef : null}
                                id={isShow ? '1' : '0'}
                                className={
                                    isShow
                                        ? styles.ShadowChatList_dropdown +
                                          ` ${styles.ShadowChatList_dropdown_show}`
                                        : styles.ShadowChatList_dropdown
                                }
                                style={
                                    isShow
                                        ? { display: 'flex' }
                                        : { display: 'none' }
                                }
                            >
                                <div
                                    className={
                                        styles.ShadowChatList_dropdown_item
                                    }
                                    onClick={() => {
                                        if (!el.el.isPined) {
                                            pinChatHandler(el.el.id)
                                        } else {
                                            unPinChatHandler(el?.el.id)
                                        }
                                    }}
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.85315 10.8537C5.94692 10.76 5.99979 10.6326 5.99979 10.5C5.99979 10.3674 5.94711 10.2402 5.85334 10.1464L5.84902 10.1422C5.75566 10.0512 5.63015 10 5.49979 10C5.36718 10 5.24 10.0527 5.14623 10.1464L2.64642 12.6463C2.55265 12.74 2.49979 12.8674 2.49979 13C2.49979 13.1326 2.55246 13.2598 2.64623 13.3536C2.74 13.4473 2.86718 13.5 2.99979 13.5C3.13239 13.5 3.25957 13.4473 3.35334 13.3536L5.85315 10.8537Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9.61356 1.28462C9.61356 1.28462 9.79887 1.20703 9.99978 1.20703C9.99978 1.20703 10.2007 1.20703 10.386 1.28462C10.386 1.28462 10.5713 1.36222 10.7096 1.50264L14.4944 5.28745C14.4944 5.28745 14.6375 5.42842 14.7151 5.61373C14.7151 5.61373 14.7927 5.79904 14.7927 5.99995C14.7927 5.99995 14.7927 6.20085 14.7151 6.38616C14.7151 6.38616 14.6375 6.57147 14.4971 6.70975L10.8434 10.3634C10.8434 10.3634 11.2031 11.3403 10.8806 12.4476C10.8806 12.4476 10.6317 13.3022 10.0182 14.1129C10.0182 14.1129 9.88693 14.2875 9.69485 14.3914C9.69485 14.3914 9.50278 14.4954 9.28485 14.5098C9.28485 14.5098 9.06693 14.5242 8.86285 14.4464C8.86285 14.4464 8.65876 14.3687 8.50873 14.216L1.77275 7.48002C1.77275 7.48002 1.62137 7.32994 1.54436 7.13117C1.54436 7.13117 1.46734 6.93241 1.47808 6.71952C1.47808 6.71952 1.48881 6.50663 1.58543 6.31662C1.58543 6.31662 1.68205 6.12661 1.84802 5.9923C1.84802 5.9923 3.15551 4.93574 4.67443 5.00357C4.67443 5.00357 5.21085 5.02752 5.62965 5.16297L9.28728 1.50536C9.28728 1.50536 9.42825 1.36222 9.61356 1.28462ZM9.99978 2.20703L6.10333 6.1035C5.95096 6.25587 5.71812 6.29348 5.52553 6.19684C5.52553 6.19684 5.18808 6.0275 4.62982 6.00257C4.62982 6.00257 3.48952 5.95165 2.47681 6.76987L9.21583 13.5089L9.21888 13.512C9.21888 13.512 9.72312 12.8456 9.92051 12.1679C9.92051 12.1679 10.1896 11.2442 9.80296 10.4743C9.70623 10.2817 9.74382 10.0488 9.89623 9.89639L13.79 6.00264L13.7927 5.99995L10.0025 2.20975L9.99978 2.20703Z"
                                            fill="#F8F8F8"
                                        />
                                    </svg>
                                    <p>
                                        {el.el.isPined
                                            ? t('chat.unpin')
                                            : t('chat.pin')}
                                    </p>
                                </div>
                                <div
                                    className={
                                        styles.ShadowChatList_dropdown_item
                                    }
                                    onClick={() => {
                                        setIsShowRename(true)
                                    }}
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M13.9962 6.21081L6.35355 13.8535C6.25979 13.9473 6.13261 13.9999 6 13.9999H3C3 13.9999 2.58579 13.9999 2.29289 13.7071C2.29289 13.7071 2 13.4142 2 12.9999L2 10.2062C2 10.2062 1.99931 10.01 2.07451 9.82692C2.07451 9.82692 2.14971 9.64382 2.28932 9.50352L9.7902 2.00264C9.7902 2.00264 9.92847 1.86222 10.1138 1.78462C10.1138 1.78462 10.2991 1.70703 10.5 1.70703C10.5 1.70703 10.7009 1.70703 10.8862 1.78462C10.8862 1.78462 11.0715 1.86222 11.2125 2.00536L13.9973 4.79014C13.9973 4.79014 14.1377 4.92842 14.2153 5.11373C14.2153 5.11373 14.2929 5.29904 14.2929 5.49995C14.2929 5.49995 14.2929 5.70085 14.2153 5.88616C14.2153 5.88616 14.1385 6.06971 13.9973 6.20975L13.9962 6.21081ZM13.2929 5.49995L13.2902 5.49725L10.5 2.70703L10.4973 2.70975L3 10.207V12.9999H5.79289L13.2902 5.50264L13.2929 5.49995Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            d="M13.5 13H5.99999C5.72385 13 5.49999 13.2239 5.49999 13.5C5.49999 13.7761 5.72386 13.9999 6 13.9999L13.5 14C13.7761 14 14 13.7761 14 13.5C14 13.2239 13.7761 13 13.5 13Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            d="M11.6462 7.85329C11.74 7.94706 11.8674 8 12 8C12.1326 8 12.2598 7.94732 12.3535 7.85355C12.4473 7.75979 12.5 7.63261 12.5 7.5C12.5 7.36739 12.4473 7.24021 12.3535 7.14645L8.85361 3.64651C8.75978 3.55268 8.6326 3.5 8.49999 3.5C8.36739 3.5 8.24021 3.55268 8.14644 3.64645C8.05267 3.74021 7.99999 3.86739 7.99999 4C7.99999 4.13261 8.05267 4.25979 8.14644 4.35355L11.6462 7.85329Z"
                                            fill="#F8F8F8"
                                        />
                                    </svg>

                                    <p>{t('chat.rename')}</p>
                                </div>
                                <div
                                    className={
                                        styles.ShadowChatList_dropdown_item
                                    }
                                    onClick={() => {
                                        deleteChat(el?.el.id)
                                    }}
                                >
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.5 3H2.5C2.22386 3 2 3.22386 2 3.5C2 3.77614 2.22386 4 2.5 4H13.5C13.7761 4 14 3.77614 14 3.5C14 3.22386 13.7761 3 13.5 3Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            d="M6 6.5V10.5C6 10.7761 6.22386 11 6.5 11C6.77614 11 7 10.7761 7 10.5V6.5C7 6.22386 6.77614 6 6.5 6C6.22386 6 6 6.22386 6 6.5Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            d="M9 6.5V10.5C9 10.7761 9.22386 11 9.5 11C9.77614 11 10 10.7761 10 10.5V6.5C10 6.22386 9.77614 6 9.5 6C9.22386 6 9 6.22386 9 6.5Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            d="M4 13V3.5C4 3.22386 3.77614 3 3.5 3C3.22386 3 3 3.22386 3 3.5V13C3 13.4142 3.29289 13.7071 3.29289 13.7071C3.58579 14 4 14 4 14H12C12.4142 14 12.7071 13.7071 12.7071 13.7071C13 13.4142 13 13 13 13V3.5C13 3.22386 12.7761 3 12.5 3C12.2239 3 12 3.22386 12 3.5V13H4Z"
                                            fill="#F8F8F8"
                                        />
                                        <path
                                            d="M5.43934 1.43934C5 1.87868 5 2.5 5 2.5V3.5C5 3.77614 5.22386 4 5.5 4C5.77614 4 6 3.77614 6 3.5V2.5C6 2.29289 6.14645 2.14645 6.14645 2.14645C6.29289 2 6.5 2 6.5 2H9.5C9.70711 2 9.85355 2.14645 9.85355 2.14645C10 2.29289 10 2.5 10 2.5V3.5C10 3.77614 10.2239 4 10.5 4C10.7761 4 11 3.77614 11 3.5V2.5C11 1.87868 10.5607 1.43934 10.5607 1.43934C10.1213 1 9.5 1 9.5 1H6.5C5.87868 1 5.43934 1.43934 5.43934 1.43934Z"
                                            fill="#F8F8F8"
                                        />
                                    </svg>

                                    <p>{t('chat.delete')}</p>
                                </div>
                            </div>
                        </>
                    </>
                )}
            </>
        )
    }

    const { t } = useTranslation()

    return (
        <div className={styles.ShadowChatList__chatButtonContainer}>
            <div
                style={{ padding: '8px 16px' }}
                onClick={createCreate}
                className={`${styles.ShadowChatList__chatButton} ${styles.ShadowChatList__chatButton_addNew}`}
            >
                <PlusIcon />
                <span>{t('chat.newChat')}</span>
            </div>
            <div className={styles.ShadowChatList__chats}>
                {chatList.length ? (
                    <>
                        {chatList
                            .filter((chat: any) => chat?.isPined)
                            .map((el: any) => (
                                <RenderCard el={el} />
                            ))}
                        {chatList
                            .filter((chat: any) => !chat?.isPined)
                            .map((el: any) => (
                                <RenderCard el={el} />
                            ))}
                    </>
                ) : null}
            </div>
        </div>
    )
}
