import { ReactComponent as HandIcon } from "../assets/HandEmoji.svg";
import { ReactComponent as GlassIcon } from "../assets/GlassIcon.svg";
import { ReactComponent as StudentIcon } from "../assets/StudentEmoji.svg";
import { ReactComponent as OfficeWorkerIcon } from "../assets/OfficeWorker.svg";
import { ReactComponent as WritersIcon } from "../assets/Writers.svg";

export const SWIPER_DATA = [
    {
        title: "Researchers",
        icon: GlassIcon
    },
    {
        title: "Students",
        icon: StudentIcon
    },
    {
        title: "Bloggers",
        icon: HandIcon
    },
    {
        title: "Managers",
        icon: OfficeWorkerIcon
    },
    {
        title: "Writers",
        icon: WritersIcon
    }
];