import React from 'react'
import styles from './style.module.scss'
import { HistoryItem } from './components'
import { useUnit } from 'effector-react'
import { $history, historyFx } from '../../../../features/history/model'

export const History: React.FC = () => {
    const [history, historyRequest, loading] = useUnit([
        $history,
        historyFx,
        historyFx.pending,
    ])

    React.useEffect(() => {
        historyRequest()
    }, [])

    return (
        <div className={styles.History}>
            <h2 className={styles.History__title}>
                ShadowGPT<span>History</span>
            </h2>
            <div className={styles.History__background} />
            <div className={styles.Content__wrapper}>
                <div className={styles.Content}>
                    {history.length
                        ? history
                              .reverse()
                              .filter((el: any) => !el.responses[0].isError)
                              .map((el: any, index: number) => (
                                  <HistoryItem
                                      date={el.createdAt}
                                      title={el.responses[0].serviceName}
                                      text={el.responses[0].text}
                                      chatId={el.chatId}
                                      request={el.request}
                                      requestId={el.requestId}
                                      requestNumber={el.chatRequestNumber}
                                      // wordsCount={wordsCount}
                                      key={`${index}-history-item`}
                                  />
                              ))
                        : null}
                </div>
            </div>
        </div>
    )
}
