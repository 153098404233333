import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as CopyIcon } from '@assets/icons/Copy.svg'
import { useTranslation } from 'react-i18next'

type Props = {
    className?: string
    text?: string
    onClick?: () => void
}

export const CopyElement: React.FC<Props> = ({
    className = '',
    text,
    onClick,
}) => {
    const { t } = useTranslation()
    return (
        <p
            className={`${styles.CopyElement} ${styles.CopyElement__title} ${className}`}
            onClick={onClick}
        >
            <span className={styles.CopyElement__caption}>
                {t(`humanizer.resultCopy`)}
            </span>
            <CopyIcon fill="#A8AAB7" />
        </p>
    )
}
