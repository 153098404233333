import React, { useEffect } from 'react'
import ReactGA from "react-ga4";
import styles from './style.module.scss'
import {ReactComponent as CheckIcon} from './assets/checkIcon.svg'
import {ButtonPrimary} from '@components'
import {ReactComponent as CrownIcon} from './assets/Crown.svg'
import {useUnit} from 'effector-react'
import {
    $pricing,
    pricingFx,
    subscribeFx,
} from '../../../features/pricing/model'
import RevolutCheckout from '@revolut/checkout'
import {$user, $userLanguage} from '../../../features/app/model'
import {useTranslation} from 'react-i18next'
import {useNavigate} from "react-router-dom";

const PricingItem: React.FC<{
    title: string
    price: string
    id: string
    features: string[]
    colorScheme: 'orange' | 'basic'
    discountEnabled: boolean
    period: string
    currency: string
}> = ({
          title,
          price,
          id,
          period,
          colorScheme,
          discountEnabled,
          features,
          currency,
      }) => {
    const currLang = useUnit($userLanguage)
    const user = useUnit($user)
    const subscribeQuery = useUnit(subscribeFx)
    const {t} = useTranslation()
    const navigate = useNavigate()

    const makeRequest = async () => {
        ReactGA.event({
            category: "InitiateCheckout",
            action: "InitiateCheckout",
            label: "InitiateCheckout",
            value: 0,
            nonInteraction: true,
            transport: "xhr",
        });

        subscribeQuery(id).then(async (res: any) => {
            const {payWithPopup} = await RevolutCheckout(
                res.token,
                process.env.REACT_APP_REVOLUT_TYPE as 'sandbox' | 'prod' | 'dev'
            )

            payWithPopup({
                onSuccess() {
                    ReactGA.event({
                        category: "Purchase",
                        action: "Purchase",
                        label: "Purchase",
                        value: res.value,
                        nonInteraction: true,
                        transport: "xhr",
                    });
                    window.alert(t('pricing.alert.thanks'))
                },
                onError(error) {
                    window.alert(`${t('pricing.alert.error')} ${error}`)
                },

                savePaymentMethodFor: 'merchant',
            })
        })
    }

    const renderCheckList = () =>
        features.length &&
        features.map((checkCaption) => (
            <div className={styles.PricingItem__checkCaptionContainer}>
                <div className={styles.PricingItem__checkIconContainer}>
                    <CheckIcon/>
                </div>
                <p>{checkCaption}</p>
            </div>
        ))

    return (
        <div className={styles.PricingItem}>
            <div className={styles.PricingItem__discountWrapper}>
                <h2>{title}</h2>
                {discountEnabled && (
                    <div className={styles.PricingItem__discount}>
                        <CrownIcon/>
                        <span className={styles.PricingItem__discount_text}>
                            {t('pricing.popular')}
                        </span>
                    </div>
                )}
            </div>
            <small>
                {price} {currency} /{' '}
                {localStorage.getItem('i18nextLng') === 'en'
                    ? 'Month'
                    : 'Månad'}
            </small>
            {renderCheckList()}
            <div
                className={styles.PricingItem__button}
                style={{marginTop: 'auto'}}
            >
                <ButtonPrimary
                    className={styles.PricingItem__buttonWrapper}
                    colorScheme={colorScheme}
                    textAlign="center"
                    onClick={() => {
                        if (localStorage.getItem('token'))
                            makeRequest()
                        else navigate("/auth")
                    }}
                >
                    <div className={styles.PricingItem__button_text}>
                        {t('pricing.getAccess')}
                    </div>
                </ButtonPrimary>
            </div>
        </div>
    )
}

export const PricingBlock: React.FC = () => {
    const user = useUnit($user)
    const currLang = useUnit($userLanguage)
    const [data, getSubscriptions] = useUnit([
        $pricing,
        pricingFx,
        pricingFx.pending,
    ])

    useEffect(() => {
        console.log(localStorage.getItem('i18nextLng'))

        if (localStorage.getItem('i18nextLng') !== null) {
            getSubscriptions({
                currencyCode:
                    localStorage.getItem('i18nextLng') === 'en' ? 'USD' : 'SEK',
                //@ts-ignore
                languageCode:
                    localStorage.getItem('i18nextLng') === 'en' ? 'EN' : 'SV',
            })
        }
    }, [currLang, getSubscriptions])

    const {t} = useTranslation()

    return (
        <div className={`app-container ${styles.PricingBlock}`}>
            <div className={styles.Pricing}>
                <h2 className={styles.Pricing__title}>{t('pricing.title')}</h2>
                <p className={styles.Pricing__plan}>
                    <span className={styles.Pricing__red}>
                        {t('pricing.monthly')}
                    </span>
                    <span className={styles.Pricing__white}>
                        / {t('pricing.annual')}{' '}
                    </span>
                    <span className={styles.Pricing__discount}>-15%</span>
                </p>
                <div className={styles.Pricing__container}>
                    {data.length
                        ? data
                            .filter((el: any) => el.period === 'month')
                            .map((el: any, index: any) => (
                                <PricingItem
                                    discountEnabled={
                                        index === 1 ? true : false
                                    }
                                    title={el.title}
                                    price={el.amount}
                                    id={el.subscriptionId}
                                    period={el.period}
                                    features={el.features}
                                    currency={el.currency.symbol}
                                    colorScheme={
                                        index === 1 ? 'orange' : 'basic'
                                    }
                                />
                            ))
                        : 'empty'}
                </div>
            </div>
        </div>
    )
}
