import React, { useState, useRef } from 'react'
import styles from './style.module.scss'

// export const VerificationInput: React.FC = () => {
//     const [inputIndex, setInputIndex] = useState(0)
//     const ref: any = useRef(null)

//     const onChange = (evt: any) => {
//         if (evt.nativeEvent.data !== null) {
//             if (/^\d+$/.test(evt.target.value)) {
//                 setInputIndex(inputIndex + 1)
//             } else {
//                 evt.target.value = ''
//             }
//         }
//     }

//     const onKeyDownHandler = (evt: React.KeyboardEvent<HTMLInputElement>) => {
//         if (
//             (evt.key === 'Backspace' || evt.key === 'Delete') &&
//             ref.current &&
//             inputIndex > 0
//         ) {
//             setInputIndex(inputIndex - 1)
//         }
//     }

//     React.useEffect(() => {
//         if (ref.current) {
//             const elements = ref.current.querySelectorAll('*')
//             if (elements[inputIndex]) {
//                 ref.current.querySelectorAll('*')[inputIndex].focus()
//             }
//         }
//     }, [inputIndex])

//     const renderInputs = () => {
//         return [1, 1, 1, 1].map((it, i) => {
//             let isDisabled = i === inputIndex ? false : true

//             return (
//                 <input
//                     key={`${i}-ver-input`}
//                     autoFocus={true}
//                     onBlur={({ target }) => target.focus()}
//                     onKeyDown={onKeyDownHandler}
//                     onChange={onChange}
//                     disabled={isDisabled}
//                     className={styles.VerificationInput__input}
//                 />
//             )
//         })
//     }

//     return (
//         <div ref={ref} className={styles.VerificationInput}>
//             {renderInputs()}
//         </div>
//     )
// }

import OtpInput from 'react-otp-input'
import { useUnit } from 'effector-react'
import {
    $verificationCode,
    verificationCodeHandler,
} from '../../../features/sign-in-form/model/model'

export const VerificationInput = () => {
    const verificationCode = useUnit($verificationCode)

    return (
        <OtpInput
            value={verificationCode}
            onChange={verificationCodeHandler}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={styles.VerificationInput}
            inputStyle={styles.VerificationInput__input}
            inputType={'number'}
        />
    )
}
