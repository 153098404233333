import React, { useState } from 'react'
import styles from './style.module.scss'
import { Logo, LanguageInfo } from '@components'
import { FooterSectionType } from './constants'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FacebookIcon } from './assets/facebook.svg'
import { ReactComponent as InstagramIcon } from './assets/instagram.svg'
import { ReactComponent as TwitterIcon } from './assets/twitter.svg'


const FooterSection: React.FC<FooterSectionType> = ({ links, title }) => {
    return (
        <div className={styles.FooterSection}>
            <h4>{title}</h4>
            <div className={styles.FooterSection__linksContainer}>
                {links.map((el: any, index: number) => (
                    <a
                        href={el.link}
                        target="_blank"
                        className={styles.FooterSection__linkContainer}
                        rel="noreferrer"
                    >
                        {el.text === 'Instagram' ? <InstagramIcon /> : null}
                        {el.text === 'Twitter' ? <TwitterIcon /> : null}
                        {el.text === 'Facebook' ? <FacebookIcon /> : null}
                        <p className={styles.FooterSection__link}>{el.text}</p>
                    </a>
                ))}
            </div>
        </div>
    )
}

export const Footer: React.FC = () => {
    const renderSections = (data: FooterSectionType[]) =>
        data.map((it) => <FooterSection {...it} />)

    const navigate = useNavigate()
    const [isShowLang, setIsShowLang] = useState<boolean>(false)

    const { t, ready } = useTranslation()

    const list = t('footer.links', { returnObjects: true })

    return (
        <div className={`app-container ${styles.Footer}`}>
            <div className={styles.Footer__flexContainer}>
                <Logo />
                <div className={styles.Footer__sectionContainer}>
                    {/* @ts-ignore */}
                    {list.length && ready ? renderSections(list) : null}
                </div>
            </div>
            <div className={styles.Copyright}>
                <small>{i18next.t('footer.copyright')}</small>
                <div className={styles.Copyright__flexWrapper}>
                    <LanguageInfo
                        set={setIsShowLang}
                        val={isShowLang}
                        position={{ bottom: '30px' }}
                    />
                    <a href="/terms/privacy">{i18next.t('footer.privacy')}</a>
                    <a href="/terms/conditions">{i18next.t('footer.terms')}</a>
                </div>
            </div>
        </div>
    )
}
