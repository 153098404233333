import React, { ButtonHTMLAttributes } from 'react'
import styles from './style.module.scss'

interface Props extends ButtonHTMLAttributes<HTMLDivElement> {
    colorScheme?: 'basic' | 'orange' | 'dark-orange'
    icon?: any
    textAlign?: 'left' | 'center'
    disabled?: boolean
    onClick?: () => void
}

export const ButtonPrimary: React.FC<Props> = (props) => {
    const {
        children,
        colorScheme = 'basic',
        className = '',
        icon,
        textAlign = 'left',
        disabled = false,
        onClick = () => {},
    } = props
    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            <div
                onClick={disabled ? undefined : onClick}
                style={{
                    textAlign,
                }}
                className={`${styles.ButtonPrimary} ${
                    styles[`ButtonPrimary__${colorScheme}-scheme`]
                } ${className} ${
                    disabled ? styles.ButtonPrimary__disabled : ''
                }`}
            >
                {children}
                {icon && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </div>
                )}
            </div>
            {disabled && <div className={styles.Mask} />}
        </div>
    )
}
