import React from 'react'
import styles from './style.module.scss'
import { ButtonPrimary } from '@components'

type Props = {
    deleteHandler: () => void
    close: any
}

export const ConfirmationPopover: React.FC<Props> = ({
    deleteHandler,
    close,
}) => {
    return (
        <div className={styles.ConfirmationPopover}>
            <div className={styles.ConfirmationPopover__container}>
                <p className={styles.ConfirmationPopover__title}>
                    Confirm delete
                </p>
                <p>This action will remove it permanently. Are you sure?</p>
                <div className={styles.ConfirmationPopover__buttons}>
                    <ButtonPrimary
                        onClick={() => close(false)}
                        className={styles.ConfirmationPopover__button}
                    >
                        Cancel
                    </ButtonPrimary>
                    <ButtonPrimary
                        onClick={deleteHandler}
                        className={styles.ConfirmationPopover__button}
                        colorScheme="orange"
                    >
                        Delete
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    )
}
