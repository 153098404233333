import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as TextIcon } from '../../assets/TextIcon.svg'

type Props = {
    name: string
    date: string
    infoCaption: string
    bottomCaption: string
    bottomIcon: any
    indicatorColor?: 'green' | 'red'
}

export const Request: React.FC<Props> = ({
    name,
    date,
    infoCaption,
    bottomCaption,
    bottomIcon,
    indicatorColor = 'green',
}) => {
    return (
        <div>
            <div className={styles.Request__textWrapper}>
                <div className={styles.Request__indicatorWrapper}>
                    <div
                        className={`${styles.Request__indicator} ${
                            indicatorColor === 'red'
                                ? styles.Request__indicator_red
                                : ''
                        }`}
                    />
                    <small>{name}</small>
                </div>
                <small className={styles.Request__time}>{date}</small>
                <small>{infoCaption}</small>
            </div>
            <div className={styles.Request__textBlock}>
                <TextIcon />
            </div>
            {/* <TextIcon1 /> */}
            <div className={styles.Request__iconContainer}>
                <small className={styles.Request__textIcon}>
                    {bottomCaption}
                </small>
                <span className={styles.Request__icon}>{bottomIcon}</span>
            </div>
        </div>
    )
}
