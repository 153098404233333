import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as CrownIcon } from '@assets/icons/Crown.svg'
import { $user } from '../../features/app/model'
import { useUnit } from 'effector-react'

export const TarifPlan: React.FC = () => {
    const user = useUnit($user)

    const humanizeDate = (date: any) => {
        const month = new Date(date).getUTCMonth() + 1
        const day = new Date(date).getUTCDate()
        const year = new Date(date).getUTCFullYear()

        if (month < 10) {
            return `0${month}` + ' / ' + day + ' / ' + year
        }
        return month + ' / ' + day + ' / ' + year
    }

    return (
        <div className={styles.TarifPlan}>
            {Object.keys(user).length && (
                <div>
                    <h5>Plan details</h5>
                    <div className={styles.TarifPlan__flexMainWrapper}>
                        <div className={styles.TarifPlan__flexWrapper}>
                            <p className={styles.TarifPlan__pContainer}>
                                Your plan
                            </p>
                            <p>-</p>
                            <p className={styles.TarifPlan__grayCaption}>
                                {user.subscription.title}
                            </p>
                        </div>
                        <div className={styles.TarifPlan__flexWrapper}>
                            <p className={styles.TarifPlan__pContainer}>
                                Requests
                            </p>
                            <p>-</p>
                            <p className={styles.TarifPlan__grayCaption}>
                                {Math.abs(
                                    user.subscription.requestsLeft -
                                        user.subscription.totalRequests
                                )}{' '}
                                / {user.subscription.totalRequests}
                            </p>
                        </div>
                        <div className={styles.TarifPlan__flexWrapper}>
                            <p className={styles.TarifPlan__pContainer}>
                                Subscription started
                            </p>
                            <p>-</p>
                            <p className={styles.TarifPlan__grayCaption}>
                                {humanizeDate(user.subscription.createdAt)}
                            </p>
                        </div>
                    </div>
                    <a
                        href="/pricing"
                        className={styles.TarifPlan__changePlanBtn}
                    >
                        <small>Change plan</small>
                        <CrownIcon width="15" height={11} />
                    </a>
                </div>
            )}
        </div>
    )
}
