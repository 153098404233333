import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from './style.module.scss'
import { LanguageInfo } from '@components'
import avatar from './assets/Avatar.png'
import { ReactComponent as ShadowGPTlogo } from '@assets/icons/ShadowGPTlogo.svg'
import { SIDE_MENU_DATA } from './constants'
import { findMenuItemByPath } from './utils'
import { ReactComponent as UserIcon } from '../../../../components/Header/assets/EmptyUser.svg'
import { ReactComponent as OutIcon } from '../assets/Out.svg'
import { useUnit } from 'effector-react'
import { $user } from '../../../../features/app/model'

export const SideMenu: React.FC = () => {
    const { pathname: activePath } = useLocation()
    const user = useUnit($user)
    const [isShowLang, setIsShowLang] = useState<boolean>(false)
    const navigate = useNavigate()

    const [logo, setLogo] = React.useState<string>('')

    const activeTitle = useMemo(
        () =>
            findMenuItemByPath(SIDE_MENU_DATA, activePath)?.title
                ? findMenuItemByPath(SIDE_MENU_DATA, activePath)?.title
                : 'Account',
        [activePath]
    )

    React.useEffect(() => {
        if (Object.keys(user).length) {
            setLogo(user.image)
        }
    }, [user])

    const avatarClickHandler = () => navigate('/generation/account-details')

    const renderMenuItems = () =>
        SIDE_MENU_DATA.map((section) => (
            <div className={styles.SideMenu__flexWrapper}>
                {section.map(({ icon: Icon, path }) => (
                    <Icon
                        onClick={() => navigate(path)}
                        className={`${styles.SideMenu__menuItem} 
            ${path === activePath ? styles.SideMenu__menuItem_active : ''}`}
                    />
                ))}
            </div>
        ))

    return (
        <div className={styles.SideMenu}>
            <div className={styles.SideMenu__title}>
                <small>ShadowGPT</small>
                <div>{activeTitle}</div>
            </div>
            <ShadowGPTlogo
                onClick={() => navigate('/')}
                className={styles.SideMenu__logo}
            />
            <div className={styles.SideMenu__mainFlexWrapper}>
                {renderMenuItems()}
                <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles.SideMenu__menuItem}
                    onClick={() => {
                        localStorage.removeItem('token')
                        localStorage.removeItem('refresh')
                        navigate('/')
                        window.location.reload()
                    }}
                >
                    <path d="M16.1893 9L12.7825 12.4069C12.6418 12.5475 12.5625 12.7386 12.5625 12.9375C12.5625 12.9495 12.5628 12.9615 12.5634 12.9735C12.5723 13.1598 12.6503 13.336 12.7822 13.4678C12.9228 13.6085 13.1136 13.6875 13.3125 13.6875C13.5114 13.6875 13.7022 13.6085 13.8428 13.4678L17.7803 9.53033C17.921 9.38968 18 9.19891 18 9C18 8.80109 17.921 8.61032 17.7803 8.46967L13.8429 4.53226C13.7022 4.39152 13.5114 4.3125 13.3125 4.3125C13.1136 4.3125 12.9228 4.39152 12.7822 4.53217C12.6415 4.67282 12.5625 4.86359 12.5625 5.0625C12.5625 5.26141 12.6415 5.45218 12.7822 5.59283L16.1893 9Z" />
                    <path d="M6.75 9.75H17.25C17.6642 9.75 18 9.41421 18 9C18 8.58579 17.6642 8.25 17.25 8.25H6.75C6.33579 8.25 6 8.58579 6 9C6 9.41421 6.33579 9.75 6.75 9.75Z" />
                    <path d="M1.5 1.5H6.75C7.16421 1.5 7.5 1.16421 7.5 0.75C7.5 0.335786 7.16421 0 6.75 0H1.5C0.87868 0 0.43934 0.43934 0.43934 0.43934C0 0.87868 0 1.5 0 1.5V16.5C0 17.1213 0.43934 17.5607 0.43934 17.5607C0.87868 18 1.5 18 1.5 18H6.75C7.16421 18 7.5 17.6642 7.5 17.25C7.5 16.8358 7.16421 16.5 6.75 16.5H1.5V1.5Z" />
                </svg>
            </div>
            <div className={styles.SideMenu__avatarContainer}>
                <div className={styles.SideMenu__avatarWrapper}>
                    {logo ? (
                        <div onClick={avatarClickHandler}>
                            <img
                                className={styles.SideMenu__avatar}
                                src={`data:image/jpeg;base64,${logo}`}
                                alt=""
                            />
                            <div
                                className={
                                    styles.SideMenu__avatarHoverContainer
                                }
                            >
                                <UserIcon fill="white" width={21} height={21} />
                            </div>
                        </div>
                    ) : (
                        <UserIcon
                            fill="white"
                            width={21}
                            style={{ cursor: 'pointer' }}
                            height={21}
                            onClick={() =>
                                navigate('/generation/account-details')
                            }
                        />
                    )}
                </div>
                <LanguageInfo
                    set={setIsShowLang}
                    val={isShowLang}
                    position={{ bottom: '30px' }}
                />
            </div>
        </div>
    )
}
