import $api from '../../http'

export const getBillingsQuery = async (page: number) => {
    try {
        const res = await $api.api.paymentsControllerGetPayments({
            limit: 9,
            page,
        })

        return res.data
    } catch (error) {
        console.log(error)
    }
}
