
import { SIDE_MENU_DATA } from '../constants/index';
export const findMenuItemByPath = (data: typeof SIDE_MENU_DATA, path: string) => {
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            if (data[i][j].path === path) {
                return data[i][j];
            }
        }
    }
    return null;
}