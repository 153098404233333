import React, { ButtonHTMLAttributes } from 'react'
import styles from './style.module.scss'
import { ReactComponent as CrossIcon } from '../Header/assets/Cross.svg'

interface Props extends ButtonHTMLAttributes<HTMLDivElement> {
    menuOpened: boolean
    closeClickHandler: () => void
    isRight?: boolean
}

export const BurgerMenu: React.FC<Props> = ({
    menuOpened,
    children,
    closeClickHandler,
    isRight = false,
}) => {
    const rightMenuClassStyles = isRight
        ? styles.BurgerMenu__menu_rightMenu
        : ''

    return (
        <>
            <div
                onClick={closeClickHandler}
                className={`${styles.Mask} ${
                    menuOpened ? styles.Mask__opened : ''
                }`}
            ></div>
            <div
                className={`${styles.BurgerMenu} ${
                    menuOpened ? styles.BurgerMenu_opened : ''
                }`}
            >
                <div
                    className={`${styles.BurgerMenu__menu} ${
                        menuOpened ? styles.BurgerMenu__menu_opened : ''
                    } ${rightMenuClassStyles}`}
                >
                    <div className={styles.BurgerMenu} />
                    {children}
                    <div
                        onClick={closeClickHandler}
                        className={
                            isRight
                                ? `${styles.BurgerMenu__menuCloseBtn} ${styles.BurgerMenu__menuCloseBtn_rightMenu}`
                                : styles.BurgerMenu__menuCloseBtn
                        }
                    >
                        <CrossIcon />
                    </div>
                </div>
            </div>
        </>
    )
}
