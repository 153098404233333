import React from 'react'
import styles from './style.module.scss'

type Props = {
    text: string
    icon: any // !!!! TEMP
    clickHandler: () => void
}

export const Action: React.FC<Props> = ({ text, icon, clickHandler }) => {
    return (
        <div
            onClick={(e: any) => {
                e.preventDefault()
                clickHandler()
            }}
            className={styles.Action}
        >
            <div>{icon}</div>
            <p className={styles.Action__caption}>{text}</p>
        </div>
    )
}
