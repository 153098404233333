import React from 'react'
import styles from './style.module.scss'

type Props = {
    title: string
    gridText1: string
    gridText2: string
    gridText3: string
    gridContent4: any
    button: any
}

export const InfoBadge: React.FC<Props> = ({
    title,
    gridText1,
    gridText2,
    gridText3,
    gridContent4,
    button,
}) => {
    return (
        <div className={styles.InfoBadge}>
            <div>
                <h5>{title}</h5>
                <div className={styles.InfoBadge__flexMainWrapper}>
                    <div className={styles.InfoBadge__flexWrapper}>
                        <p className={styles.InfoBadge__pContainer}>
                            {gridText1}
                        </p>
                        <p>-</p>
                        <p className={styles.InfoBadge__grayCaption}>
                            {gridText2}
                        </p>
                    </div>
                    <div className={styles.InfoBadge__flexWrapper}>
                        <p className={styles.InfoBadge__pContainer}>
                            {gridText3}
                        </p>
                        <p>-</p>
                        <p className={styles.InfoBadge__grayCaption}>
                            {gridContent4}
                        </p>
                    </div>
                </div>
                {button}
            </div>
        </div>
    )
}
