import { useEffect } from 'react'
import styles from './style.module.scss'
import USAFlagIcon from '@assets/icons/countryFlags/USAFlag.svg'
import SEFlagIcon from '@assets/icons/countryFlags/se.svg'
import { useUnit } from 'effector-react'
import useOnclickOutside from 'react-cool-onclickoutside'
import {
    $user,
    $userLanguage,
    updateUserLangFx,
    userLanaguageEvent,
} from '../../features/app/model'
import { useTranslation } from 'react-i18next'
import { pricingFx } from '../../features/pricing/model'

export const LanguageInfo = ({ position, set, val }: any) => {
    const currentLang = useUnit($userLanguage)
    const [user, update] = useUnit([$user, updateUserLangFx])
    const requestSubs = useUnit(pricingFx)
    const { i18n } = useTranslation()

    const ref = useOnclickOutside(() => {
        set(false)
    })

    const changeLanguage = (lang: 'EN' | 'SV') => {
        userLanaguageEvent(lang)
        i18n.changeLanguage(lang.toLocaleLowerCase())
        if (Object.keys(user).length)
            update({
                languageCode: lang,
                currencyCode: lang === 'EN' ? 'USD' : 'SEK',
            }).then((res) => {
                requestSubs({
                    currencyCode: lang === 'EN' ? 'USD' : 'SEK',
                    languageCode: lang,
                })
            })
        else {
            requestSubs({
                currencyCode: lang === 'EN' ? 'USD' : 'SEK',
                languageCode: lang,
            })
        }
        set(false)
    }

    return (
        <div className={styles.LanguageInfo} ref={ref}>
            <div
                className={styles.LanguageInfo__header}
                onClick={() => set(!val)}
            >
                {localStorage.getItem('i18nextLng') === 'en' ||
                localStorage.getItem('i18nextLng') === 'en-US' ? (
                    <img src={USAFlagIcon} alt="" />
                ) : null}
                {localStorage.getItem('i18nextLng') === 'sv' && (
                    <img src={SEFlagIcon} alt="" />
                )}
                <p className={styles.LanguageInfo__language}>
                    {localStorage.getItem('i18nextLng') === 'en' ||
                    localStorage.getItem('i18nextLng') === 'en-US'
                        ? 'EN'
                        : 'SE'}
                </p>
            </div>
            <div
                style={position}
                className={
                    val
                        ? styles.LanguageInfoBody__show +
                          ' ' +
                          styles.LanguageInfoBody
                        : styles.LanguageInfoBody
                }
            >
                <div
                    className={styles.LanguageInfoBody__row}
                    onClick={() => changeLanguage('EN')}
                >
                    <img src={USAFlagIcon} alt="" />
                    <p className={styles.LanguageInfo__language}>EN</p>
                </div>
                <div
                    className={styles.LanguageInfoBody__row}
                    onClick={() => changeLanguage('SV')}
                >
                    <img src={SEFlagIcon} alt="" />
                    <p className={styles.LanguageInfo__language}>SE</p>
                </div>
            </div>
        </div>
    )
}
