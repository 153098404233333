import { useUnit } from 'effector-react'
import styles from './style.module.scss'
import {
    $dropDownShow,
    dropDownHandler,
} from '../../../../features/header/model'
import { $user } from '../../../../features/app/model'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const HeaderDropdown = () => {
    const navigate = useNavigate()
    const user = useUnit($user)
    const isShow = useUnit($dropDownShow)

    const logout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        dropDownHandler(false)
        window.location.reload()
        navigate('/')
    }

    const { t } = useTranslation()

    return (
        <div
            className={
                isShow
                    ? styles.HeaderPcDropdown__show +
                      ' ' +
                      styles.HeaderPcDropdown
                    : styles.HeaderPcDropdown
            }
        >
            <div className={styles.Progress_block}>
                <h3>
                    {user?.subscription ? user.subscription.title : 'TRIAL'}{' '}
                    {t('chat.plan')}
                </h3>
                <div className={styles.Progress_block_bar}>
                    {user.subscription ? (
                        <div
                            style={{
                                width:
                                    ((user.subscription.totalRequests -
                                        user.subscription.requestsLeft) /
                                        user.subscription.totalRequests) *
                                        100 +
                                    '%',
                            }}
                        ></div>
                    ) : (
                        <div
                            style={{
                                width: '0%',
                            }}
                        ></div>
                    )}
                </div>
                {Object.keys(user).length && user.subscription ? (
                    <small>
                        {Math.abs(
                            user.subscription.requestsLeft -
                                user.subscription.totalRequests
                        )}{' '}
                        / {user.subscription.totalRequests} {t('chat.requests')}
                    </small>
                ) : (
                    <small>0/0 {t('chat.requests')}</small>
                )}
                <div className={styles.HeaderPcDropdown_links}>
                    <a href="/generation/billings">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.5 3.125C7.5 3.125 10.223 3.125 12.1759 4.00381C12.1759 4.00381 13.1545 4.4442 13.7143 5.0397C13.7143 5.0397 14.375 5.74268 14.375 6.5625C14.375 6.5625 14.375 7.38232 13.7143 8.0853C13.7143 8.0853 13.1545 8.6808 12.1759 9.12119C12.1759 9.12119 10.223 10 7.5 10C7.5 10 4.77702 10 2.8241 9.12119C2.8241 9.12119 1.84547 8.6808 1.28575 8.0853C1.28575 8.0853 0.625 7.38232 0.625 6.5625C0.625 6.5625 0.625 5.74268 1.28575 5.0397C1.28575 5.0397 1.84547 4.4442 2.8241 4.00381C2.8241 4.00381 4.77702 3.125 7.5 3.125ZM7.5 4.375C7.5 4.375 5.04531 4.375 3.33706 5.14371C3.33706 5.14371 2.58564 5.48185 2.19657 5.8958C2.19657 5.8958 1.875 6.23792 1.875 6.5625C1.875 6.5625 1.875 6.88708 2.19657 7.2292C2.19657 7.2292 2.58564 7.64315 3.33706 7.98129C3.33706 7.98129 5.04531 8.75 7.5 8.75C7.5 8.75 9.95469 8.75 11.6629 7.98129C11.6629 7.98129 12.4144 7.64315 12.8034 7.2292C12.8034 7.2292 13.125 6.88708 13.125 6.5625C13.125 6.5625 13.125 6.23792 12.8034 5.8958C12.8034 5.8958 12.4144 5.48185 11.6629 5.14371C11.6629 5.14371 9.95469 4.375 7.5 4.375Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M14.375 9.6875V6.5625C14.375 6.21732 14.0952 5.9375 13.75 5.9375C13.4048 5.9375 13.125 6.21732 13.125 6.5625V9.6875C13.125 10.0127 12.8035 10.3549 12.8035 10.3549C12.4147 10.7688 11.6635 11.1068 11.6635 11.1068C9.95602 11.875 7.5 11.875 7.5 11.875C5.04398 11.875 3.33652 11.1068 3.33652 11.1068C2.58533 10.7688 2.19649 10.3549 2.19649 10.3549C1.875 10.0127 1.875 9.6875 1.875 9.6875V6.5625C1.875 6.21732 1.59518 5.9375 1.25 5.9375C0.904823 5.9375 0.625 6.21732 0.625 6.5625V9.6875C0.625 10.5077 1.28544 11.2108 1.28544 11.2108C1.84503 11.8064 2.82364 12.2467 2.82364 12.2467C4.77573 13.125 7.5 13.125 7.5 13.125C10.2243 13.125 12.1764 12.2467 12.1764 12.2467C13.155 11.8064 13.7146 11.2108 13.7146 11.2108C14.375 10.5077 14.375 9.6875 14.375 9.6875Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M4.375 9.14062V12.2656C4.375 12.6108 4.65482 12.8906 5 12.8906C5.34518 12.8906 5.625 12.6108 5.625 12.2656V9.14062C5.625 8.79545 5.34518 8.51562 5 8.51562C4.65482 8.51562 4.375 8.79545 4.375 9.14062Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M13.8097 6.93256C16.0954 7.14574 17.6181 7.97449 17.6181 7.97449C19.375 8.9307 19.375 10.3125 19.375 10.3125C19.375 11.1328 18.7146 11.8358 18.7146 11.8358C18.155 12.4315 17.1764 12.8717 17.1764 12.8717C15.2243 13.75 12.5 13.75 12.5 13.75C10.1189 13.75 8.25949 13.0549 8.25949 13.0549C8.25864 13.0545 8.25779 13.0542 8.25779 13.0542C8.10254 12.9961 7.9765 12.8787 7.90779 12.7278C7.87377 12.6531 7.85479 12.5725 7.85194 12.4905C7.85169 12.4832 7.85156 12.476 7.85156 12.4688C7.85156 12.394 7.86498 12.3198 7.89118 12.2498C7.98101 12.0097 8.20837 11.8487 8.4647 11.8439L8.47656 11.8438C8.55134 11.8438 8.62551 11.8572 8.69555 11.8834C10.3438 12.5 12.5 12.5 12.5 12.5C14.956 12.5 16.6635 11.7318 16.6635 11.7318C17.4147 11.3938 17.8035 10.9799 17.8035 10.9799C18.125 10.6377 18.125 10.3125 18.125 10.3125C18.125 9.67352 17.0206 9.07241 17.0206 9.07241C15.723 8.36619 13.692 8.17701 13.692 8.17701C13.3707 8.14708 13.125 7.87742 13.125 7.5547C13.125 7.53595 13.1258 7.51722 13.1275 7.49854L13.1277 7.49673C13.1412 7.35163 13.205 7.21581 13.3081 7.11276C13.3217 7.09908 13.3361 7.08603 13.351 7.07368C13.4631 6.98063 13.6043 6.9297 13.75 6.9297C13.7687 6.9297 13.7875 6.93055 13.8062 6.93223L13.808 6.9324L13.8097 6.93256Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M19.375 13.4375V10.3125C19.375 9.96734 19.0952 9.6875 18.75 9.6875C18.4048 9.6875 18.125 9.96734 18.125 10.3125V13.4375C18.125 13.7627 17.8035 14.1049 17.8035 14.1049C17.4147 14.5188 16.6635 14.8568 16.6635 14.8568C14.956 15.625 12.5 15.625 12.5 15.625C10.044 15.625 8.33652 14.8568 8.33652 14.8568C7.58533 14.5188 7.19649 14.1049 7.19649 14.1049C6.875 13.7627 6.875 13.4375 6.875 13.4375L6.875 12.4453C6.875 12.1001 6.59518 11.8203 6.25 11.8203C5.90482 11.8203 5.625 12.1001 5.625 12.4453L5.625 13.4375C5.625 14.2577 6.28544 14.9608 6.28544 14.9608C6.84503 15.5564 7.82364 15.9967 7.82364 15.9967C9.77573 16.875 12.5 16.875 12.5 16.875C15.2243 16.875 17.1764 15.9967 17.1764 15.9967C18.155 15.5564 18.7146 14.9608 18.7146 14.9608C19.375 14.2577 19.375 13.4375 19.375 13.4375Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M14.375 12.8906V16.0156C14.375 16.3608 14.6548 16.6406 15 16.6406C15.3452 16.6406 15.625 16.3608 15.625 16.0156V12.8906C15.625 12.5454 15.3452 12.2656 15 12.2656C14.6548 12.2656 14.375 12.5454 14.375 12.8906Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M9.375 9.14062V16.0156C9.375 16.3608 9.65482 16.6406 10 16.6406C10.3452 16.6406 10.625 16.3608 10.625 16.0156V9.14062C10.625 8.79545 10.3452 8.51562 10 8.51562C9.65482 8.51562 9.375 8.79545 9.375 9.14062Z"
                                fill="#F8F8F8"
                            />
                        </svg>
                        {t("header.dropdown.billings")}
                    </a>
                    <a href="/generation/history">
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.37502 6.25002V10C9.37502 10.3452 9.65484 10.625 10 10.625C10.3452 10.625 10.625 10.3452 10.625 10V6.25002C10.625 5.90484 10.3452 5.62502 10 5.62502C9.65484 5.62502 9.37502 5.90484 9.37502 6.25002Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M10.3124 9.4587L13.5623 11.3337C13.7451 11.4391 13.8623 11.6296 13.874 11.8402C13.8747 11.8518 13.875 11.8634 13.875 11.875C13.875 11.9847 13.8462 12.0924 13.7914 12.1873C13.686 12.3701 13.4955 12.4873 13.2848 12.4991C13.2732 12.4997 13.2616 12.5 13.25 12.5C13.1404 12.5 13.0327 12.4712 12.9377 12.4164L9.68777 10.5414C9.54723 10.4604 9.44376 10.3279 9.39914 10.172L9.39636 10.162C9.38219 10.1092 9.37502 10.0547 9.37502 10C9.37502 9.90013 9.39896 9.8017 9.44484 9.71296C9.44925 9.70444 9.45385 9.69601 9.45865 9.6877C9.53968 9.54724 9.67214 9.44377 9.82804 9.39915L9.83806 9.39637C9.89088 9.3822 9.94533 9.37502 10 9.37502C10.0999 9.37502 10.1983 9.39897 10.2871 9.44485C10.2956 9.44925 10.304 9.45386 10.3124 9.4587Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M3.10938 7.1641V4.6641C3.10938 4.31892 2.82955 4.0391 2.48438 4.0391C2.1392 4.0391 1.85938 4.31892 1.85938 4.6641V7.7891C1.85938 8.13428 2.1392 8.4141 2.48438 8.4141H5.60938C5.95455 8.4141 6.23438 8.13428 6.23438 7.7891C6.23438 7.44392 5.95455 7.1641 5.60938 7.1641H3.10938Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M4.69933 4.69803L2.04308 7.34647C1.92548 7.46373 1.85938 7.62302 1.85938 7.7891C1.85938 7.79958 1.85964 7.81001 1.86017 7.82048C1.86794 7.97486 1.93264 8.12089 2.04178 8.23035C2.15905 8.34796 2.3183 8.4141 2.48438 8.4141C2.49485 8.4141 2.50533 8.41379 2.51579 8.41327C2.67018 8.4055 2.8162 8.34079 2.92567 8.23165L5.58192 5.58321C6.92306 4.24097 8.78278 3.87046 8.78278 3.87046C10.6425 3.49995 12.3946 4.22526 12.3946 4.22526C14.1466 4.95057 15.2003 6.52715 15.2003 6.52715C16.254 8.10373 16.254 10 16.254 10C16.254 11.8963 15.2003 13.4728 15.2003 13.4728C14.1466 15.0494 12.3946 15.7747 12.3946 15.7747C10.6425 16.5 8.78278 16.1295 8.78278 16.1295C6.92306 15.759 5.58275 14.4176 5.58275 14.4176C5.46552 14.3003 5.30648 14.2344 5.14063 14.2344C5.12993 14.2344 5.11923 14.2346 5.10854 14.2352C4.95417 14.2431 4.80821 14.308 4.69886 14.4172C4.58161 14.5344 4.51569 14.6934 4.51562 14.8591C4.51562 14.869 4.51586 14.8792 4.51633 14.8891C4.52373 15.0443 4.58868 15.1912 4.6985 15.3011C6.30682 16.9107 8.53854 17.3554 8.53854 17.3554C10.7702 17.8001 12.8727 16.9297 12.8727 16.9297C14.9752 16.0593 16.2396 14.1674 16.2396 14.1674C17.504 12.2755 17.504 10 17.504 10C17.504 7.72448 16.2396 5.83258 16.2396 5.83258C14.9752 3.94069 12.8727 3.07031 12.8727 3.07031C10.7702 2.19994 8.53854 2.64455 8.53854 2.64455C6.30688 3.08917 4.69933 4.69803 4.69933 4.69803Z"
                                fill="#F8F8F8"
                            />
                        </svg>
                        {t("header.dropdown.history")}
                    </a>
                    <div onClick={logout}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.9911 10L13.152 12.8391C13.0348 12.9563 12.9688 13.1155 12.9688 13.2812C12.9688 13.2913 12.969 13.3013 12.9695 13.3113C12.9769 13.4665 13.0419 13.6133 13.1518 13.7232C13.269 13.8404 13.428 13.9062 13.5938 13.9062C13.7595 13.9062 13.9185 13.8404 14.0357 13.7232L17.3169 10.4419C17.4342 10.3247 17.5 10.1658 17.5 10C17.5 9.83424 17.4342 9.67527 17.3169 9.55806L14.0358 6.27688C13.9185 6.1596 13.7595 6.09375 13.5938 6.09375C13.428 6.09375 13.269 6.1596 13.1518 6.27681C13.0346 6.39402 12.9688 6.55299 12.9688 6.71875C12.9688 6.88451 13.0346 7.04348 13.1518 7.16069L15.9911 10Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M8.125 10.625H16.875C17.2202 10.625 17.5 10.3452 17.5 10C17.5 9.65482 17.2202 9.375 16.875 9.375H8.125C7.77982 9.375 7.5 9.65482 7.5 10C7.5 10.3452 7.77982 10.625 8.125 10.625Z"
                                fill="#F8F8F8"
                            />
                            <path
                                d="M3.75 3.75H8.125C8.47018 3.75 8.75 3.47018 8.75 3.125C8.75 2.77982 8.47018 2.5 8.125 2.5H3.75C3.23223 2.5 2.86612 2.86612 2.86612 2.86612C2.5 3.23223 2.5 3.75 2.5 3.75V16.25C2.5 16.7678 2.86612 17.1339 2.86612 17.1339C3.23223 17.5 3.75 17.5 3.75 17.5H8.125C8.47018 17.5 8.75 17.2202 8.75 16.875C8.75 16.5298 8.47018 16.25 8.125 16.25H3.75V3.75Z"
                                fill="#F8F8F8"
                            />
                        </svg>
                        {t("header.dropdown.logOut")}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderDropdown
