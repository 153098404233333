import React from "react";
import styles from "./style.module.scss";
import { ButtonPrimary } from "@components";

type Props = {
  title: string;
  description: string;
  icon: any;
  buttonTitle: string;
  colorScheme: "orange" | "basic";
  onClick: () => void
};

export const Prompt: React.FC<Props> = ({
  title,
  buttonTitle,
  icon: Icon,
  description,
  colorScheme,
  onClick
}) => {
  return (
    <div className={styles.Prompt} onClick={onClick}>
      <p className={styles.Prompt__title}>{title}</p>
      <p className={styles.Prompt__description}>{description}</p>
      <ButtonPrimary className={styles.Prompt__button} colorScheme={colorScheme}>
        <div>{buttonTitle}</div>
        {Icon}
      </ButtonPrimary>
    </div>
  );
};
