import $api from '../../http'
import { BodyMakeUniqueTextDto } from '../../http/Api'

export const chatListQuery = async () => {
    try {
        const res = await $api.api.chatsControllerGetList()
        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

export const newChatQuery = async (name: string) => {
    try {
        const res = await $api.api.chatsControllerCreate({ name })
        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

export const deleteChatQuery = async (chatId: string) => {
    try {
        const res = $api.api.chatsControllerDelete(chatId)
        return res
    } catch (error) {
        console.log(error)
    }
}

export const messageQuery = async ({ text, chatId }: BodyMakeUniqueTextDto) => {
    try {
        const res = await $api.api.textsControllerMakeUnique({
            text,
            chatId,
        })

        return res.data.data!.responses[0]
    } catch (error) {
        console.log(error)
    }
}

export const getMessages = async (chatId: string) => {
    try {
        const res = await $api.api.chatsControllerGet({
            chatId,
            offset: 0,
            limit: 20,
        })
        return res.data.data?.messages.reverse()
    } catch (error) {
        console.log(error)
    }
}

export const pinChat = async (chatId: string) => {
    try {
        const res = await $api.api.chatsControllerPin(chatId)

        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

export const unPinChat = async (chatId: string) => {
    try {
        const res = await $api.api.chatsControllerUnpin(chatId)

        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

type RenamePayload = {
    chatId: string
    val: string
}

export const renameChatQuery = async (payload: RenamePayload) => {
    try {
        const res = await $api.api.chatsControllerUpdate(payload.chatId, {
            name: payload.val,
        })

        return res.data.data
    } catch (error) {
        console.log(error)
    }
}
