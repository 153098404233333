import { createEffect, createEvent, createStore, sample } from 'effector'
import { updateUserLangQuery, updateUserQuery, userDeleteQuery, userQuery } from './queries'

export const userFx = createEffect(userQuery)
export const $user = createStore<any>({}).on(
    userFx.doneData,
    (_store, res) => res
)

// for google tag manager (facebook)
// @ts-ignore
window["userStore"] = $user;

export const userHandler = createEvent<any | null>()

sample({
    clock: userFx.doneData,
    fn: (clk: any) => clk,
    target: userHandler,
})

export const userDeleteFx = createEffect(userDeleteQuery)
export const $userDelete = createStore<any>(null)
export const userDeleteHandler = createEvent<any>()

sample({
    target: userDeleteHandler,
    fn: (clk) => clk,
    clock: userDeleteFx.doneData,
})

export const updateUserFx = createEffect(updateUserQuery)
export const $updateUser = createStore<any>(null).on(
    updateUserFx.doneData,
    (_store, res) => res
)
export const updateUserEvent = createEvent()

sample({
    fn: (clk: any) => clk,
    target: updateUserEvent,
    clock: updateUserFx.doneData,
})

export const $userLanguage = createStore<"EN" | "SV">('EN')
export const userLanaguageEvent = createEvent<"EN" | "SV">()

sample({
    clock: userLanaguageEvent,
    target: $userLanguage,
})


export const $updateUserLang = createStore<any>({})
export const updateUserLangFx = createEffect(updateUserLangQuery)
export const updateUserLangEvent = createEvent()


sample({
    fn: (clk: any) => clk,
    target: updateUserLangEvent,
    clock: updateUserLangFx.doneData,
})
