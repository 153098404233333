import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'swiper/css'
import './index.scss'
import { GoogleOAuthProvider } from '@react-oauth/google'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_URL as string}>
        <Suspense>
            <App />
        </Suspense>
    </GoogleOAuthProvider>
)

reportWebVitals()
