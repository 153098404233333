import React, { useEffect, useRef } from 'react'
import styles from './style.module.scss'
import { ReactComponent as SendIcon } from './assets/Send.svg'
import {
    $activeChat,
    $chatInput,
    chatInputHandler,
} from '../../../../../../features/chat/model'
import { useUnit } from 'effector-react'
import { $user } from '../../../../../../features/app/model'
import { useTranslation } from 'react-i18next'
import { makeToast } from '../../../../../../shared/ui/toast'
type Props = {
    callback: any
    getStarted?: boolean
    getStartedFunc?: any
}

export const Input: React.FC<Props> = ({
    callback,
    getStarted,
    getStartedFunc,
}) => {
    const user = useUnit($user)
    const inputValue = useUnit($chatInput)
    const currentChat = useUnit($activeChat)
    const inputRef: any = useRef(null)
    const maskRef: any = useRef(null)

    useEffect(() => {
        if (inputRef.current && maskRef.current) {
            const scrollHeight = maskRef.current.scrollHeight
            inputRef.current.style.height = `${scrollHeight.toString()}px`
        }
    }, [inputValue, inputRef, maskRef])
    const { t } = useTranslation()
    const sendButtonClickHandler = () => {
        if (Object.keys(user).length && user.subscription.requestsLeft) {
            if (inputValue.replace(/\n/g, '').length > 2) {
                callback(inputValue, currentChat.id)
                user.subscription.requestsLeft =
                    user.subscription.requestsLeft > 0
                        ? user.subscription.requestsLeft - 1
                        : null
                chatInputHandler('')
                maskRef.current.value = ''
                inputRef.current.value = ''
            } else makeToast('error', t('history.toast.tooShort'))
        }
    }

    return (
        <div
            className={styles.Input__container}
            style={currentChat ? { position: 'absolute', bottom: '40px' } : {}}
        >
            <div
                className={styles.Input}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13 && !e.shiftKey) {
                        if (e.key === 'Enter' && !getStarted) {
                            sendButtonClickHandler()
                            e.preventDefault()
                        }
                        if (e.key === 'Enter' && getStarted) {
                            getStartedFunc()
                            e.preventDefault()
                        }
                    }
                }}
            >
                {user.subscription ? (
                    <textarea
                        ref={inputRef}
                        value={inputValue}
                        onChange={(evt) => chatInputHandler(evt.target.value)}
                        className={styles.Input__textarea}
                        disabled={!user.subscription.requestsLeft}
                        placeholder={t('chat.inputPlaceholder')}
                    />
                ) : null}

                <SendIcon
                    onClick={() => {
                        if (!getStarted) sendButtonClickHandler()
                    }}
                    className={styles.Input__icon}
                />
            </div>
            <div
                style={{
                    opacity: 0,
                    zIndex: -999,
                    overflow: 'hidden',
                    visibility: 'hidden',
                }}
                className={styles.Input}
            >
                <textarea
                    style={{
                        overflow: 'hidden',
                    }}
                    value={inputValue}
                    ref={maskRef}
                    rows={1}
                    className={styles.Input__textarea}
                    placeholder={t('chat.inputPlaceholder')}
                />
            </div>
        </div>
    )
}
