import React from 'react'
import { Outlet } from 'react-router-dom'
import styles from './style.module.scss'
import { Header } from '@components'
import { SideMenu } from './components'

export const GenerationBlock: React.FC = () => {
    return (
        <div className={styles.GenerationBlock}>
            <SideMenu />
            <div className={styles.MobileHeader}>
                <Header />
            </div>
            <div className={styles.GenerationBlock__page}>
                <Outlet />
            </div>
        </div>
    )
}
