import React, { useState, RefObject, useRef, useEffect } from 'react'
import styles from './style.module.scss'
import { ButtonPrimary, CopyElement, TextDetectionResult } from '@components'

import { ReactComponent as AiIcon } from '../../assets/icons/AI.svg'
import { ReactComponent as StickIcon } from '../../assets/icons/Stick.svg'
import { ReactComponent as CopyIcon } from './assets/svg/Copy.svg'
import { ReactComponent as SampleIcon } from './assets/svg/Sample.svg'
import { SAMPLE_TEXT } from './constants'
import { Action } from './components'
import { useUnit } from 'effector-react'
import { $user } from '../../features/app/model'
import { useNavigate } from 'react-router-dom'
import {
    $aiDetect,
    $aiReDetect,
    $humanizedText,
    $humanizerInputValue,
    aiDetectFx,
    aiReDecectFx,
    humanizeFx,
    humanizerInputHandler,
} from '../../features/humanizer/model'
import { useTranslation } from 'react-i18next'
import { makeToast } from '../../shared/ui/toast'

const countWords = (str: string) => {
    str = str.trim()
    return str.split(/\s+/).length
}

export const HumanizerInput: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
    props
) => {
    const { t } = useTranslation()
    const [humanizer, humanizerLoading] = useUnit([
        humanizeFx,
        humanizeFx.pending,
    ])
    const humanizedText = useUnit($humanizedText)

    const [aiDetect, aiDetectLoading] = useUnit([
        aiDetectFx,
        aiDetectFx.pending,
    ])

    const reDetectAi = useUnit($aiReDetect)

    const [reDetectFx, reDetectLoading] = useUnit([
        aiReDecectFx,
        aiReDecectFx.pending,
    ])

    const inputValue = useUnit($humanizerInputValue)

    const aiDetectRes = useUnit($aiDetect)
    const user = useUnit($user)

    const navigate = useNavigate()

    const ref: RefObject<HTMLDivElement> = useRef(null)
    const textareaRef: RefObject<HTMLTextAreaElement> = useRef(null)

    const [containerMaxHeqight, setContainerMaxHeight] = useState<any>('auto')
    const [textDetectionShow, setTextDetectionShow] = useState(false)
    const [aiReDetectShow, setAiReDetectShow] = useState(false)
    const [maxWords, setMaxWords] = useState(400)

    useEffect(() => {
        if (Object.keys(user) && user.subscription) {
            setMaxWords(user.subscription.maxWords)
        }
    }, [user])

    const textAreaChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        humanizerInputHandler(e.target.value)

    const pasteActionClickHandler = async () => {
        const text = await navigator.clipboard.readText()
        humanizerInputHandler(text)
        textareaRef.current?.focus()
    }

    const tryASampleClickHandler = () => {
        humanizerInputHandler(SAMPLE_TEXT)
    }

    const humanizerHandler = async () => {
        let enoghRequests = user.subscription.requestsLeft > 0
        let check = Object.keys(user).length && inputValue.length > 2
        if (enoghRequests) {
            if (check) {
                user.subscription.requestsLeft =
                    user.subscription.requestsLeft - 1
                humanizer(inputValue)
            } else makeToast('error', t('history.toast.tooShort'))
        } else {
            makeToast('error', t('humanizer.toast.noRequests'))
        }
    }

    // useEffect(() => {
    //   if (inputValue.length > 400) {
    //     makeToast("error", "long pennis")
    //   }
    // }, [])

    const detectAi = (type: string = '') => {
        if (inputValue.length > 2) {
            if (type === '') {
                setTextDetectionShow(true)
                aiDetect(inputValue)
            } else if (humanizedText && type !== '') {
                console.log(countWords(humanizedText), 'humanizedText.length')

                if (countWords(humanizedText) >= maxWords) {
                    makeToast('error', t('humanizer.toast.tooLong'))
                } else {
                    setAiReDetectShow(true)
                    reDetectFx(humanizedText)
                }
            }
        } else
            makeToast('error', 'It is necessary to fill in the required fields')
    }

    const renderActions = () => {
        return inputValue ? null : (
            <div className={styles.ActionWrapper}>
                <div className={styles.ActionWrapper__container}>
                    <Action
                        clickHandler={() => {
                            tryASampleClickHandler()
                        }}
                        icon={<SampleIcon />}
                        text={t(`humanizer.trySample`)}
                    />
                    <Action
                        clickHandler={() => {
                            pasteActionClickHandler()
                        }}
                        icon={<CopyIcon width={18} fill="white" height={18} />}
                        text={t(`humanizer.pasteText`)}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (ref.current) {
            setContainerMaxHeight(ref.current?.getBoundingClientRect().height)
        }
    }, [])

    const disableButton = () => {
        if (inputValue.length > 2) {
            if (user.subscription && countWords(inputValue) <= maxWords) {
                return false
            } else if (!user.subscription && countWords(inputValue) <= 400)
                return false
            else return true
        }
        return true
    }

    useEffect(() => {
        const delay = setTimeout(() => {
            if (countWords(inputValue) >= maxWords) {
                makeToast('error', t('humanizer.toast.tooLong'))
            }
        }, 4000)

        return () => clearTimeout(delay)
    }, [inputValue, user, t, maxWords])

    return (
        <div
            ref={ref}
            className={`${styles.HumanizerInput} ${
                props.className ? props.className : ''
            } ${textDetectionShow ? styles.HumanizerInput_animated : ''}`}
        >
            <div className={styles.HumanizerInput__flexWrapper}>
                <div
                    className={`${styles.HumanizerInput__input} ${styles.HumanizerInput__rightBorder}`}
                >
                    <div
                        className={`${styles.HumanizerInput__flexWrapper} ${styles.HumanizerInput__aliginMod}`}
                    >
                        <p className={styles.HumanizerInput__title}>
                            {t(`humanizer.yourInput`)}
                        </p>
                        <p className={styles.HumanizerInput__title}>
                            <span className={styles.HumanizerInput__basicPlan}>
                                {Object.keys(user).length
                                    ? user.subscription.title
                                    : 'General'}{' '}
                                Plan
                            </span>{' '}
                            <span className={styles.HumanizerInput__greyColor}>
                                {countWords(inputValue)} / {maxWords}{' '}
                                {` ${t('history.words')}`}
                            </span>
                        </p>
                    </div>
                    <div className={styles.HumanizerInput__fieldWrapper}>
                        {renderActions()}
                        <textarea
                            // disabled={Object.keys(user).length ? false : true}
                            ref={textareaRef}
                            onChange={textAreaChangeHandler}
                            value={inputValue}
                            className={styles.HumanizerInput__field}
                            placeholder={t(`humanizer.placeholder1`)}
                        />
                    </div>
                    <div className={`${styles.buttons}`}>
                        <ButtonPrimary
                            disabled={disableButton()}
                            onClick={() => {
                                detectAi()
                            }}
                            className={styles.buttons__centeringMod}
                            icon={<AiIcon />}
                            colorScheme="dark-orange"
                        >
                            {t(`humanizer.scanForAi`)}
                        </ButtonPrimary>
                        <ButtonPrimary
                            onClick={() => {
                                if (Object.keys(user).length) {
                                    humanizerHandler()
                                } else {
                                    navigate('/auth')
                                }
                            }}
                            disabled={
                                inputValue.length > 2 &&
                                countWords(inputValue) <= maxWords
                                    ? false
                                    : true
                            }
                            className={styles.buttons__centeringMod}
                            icon={<StickIcon />}
                            colorScheme="orange"
                        >
                            {t(`humanizer.humanizeBtn`)}
                        </ButtonPrimary>
                    </div>
                    <div>
                        {textDetectionShow && (
                            <TextDetectionResult
                                data={aiDetectRes}
                                bottomAiResultBadge={false}
                                loading={aiDetectLoading}
                            />
                        )}
                    </div>
                </div>
                <div className={`${styles.HumanizerInput__result}`}>
                    <div className={`${styles.HumanizerInput__copyContainer}`}>
                        <p className={styles.HumanizerInput__title}>
                            {t(`humanizer.result`)}
                        </p>
                        <CopyElement
                            onClick={() =>
                                humanizedText.length &&
                                navigator.clipboard.writeText(humanizedText)
                            }
                        />
                    </div>
                    {humanizerLoading ? (
                        <div className={styles.Loader}>
                            <div className={styles.Loader__bar}>
                                <div
                                    className={
                                        styles.Loader__bar +
                                        ' ' +
                                        styles.Loader__barAnimated
                                    }
                                ></div>
                            </div>
                            <p> {t(`humanizer.processing`)}</p>
                        </div>
                    ) : (
                        <textarea
                            disabled={true}
                            className={styles.HumanizerInput__result_text}
                        >
                            {humanizedText
                                ? humanizedText
                                : t(`humanizer.resultPlaceholder`)}
                        </textarea>
                    )}
                    <div
                        className={`${styles.buttons} ${styles.buttonAdaptive}`}
                        style={humanizerLoading ? { marginTop: 'auto' } : {}}
                    >
                        {humanizedText && (
                            <>
                                <ButtonPrimary
                                    onClick={() => detectAi('redetect')}
                                    className={styles.buttons__centeringMod}
                                    icon={<AiIcon />}
                                    colorScheme="dark-orange"
                                >
                                    {t(`humanizer.rescanAi`)}
                                </ButtonPrimary>
                                <ButtonPrimary
                                    onClick={() => {
                                        setAiReDetectShow(false)
                                        humanizerHandler()
                                    }}
                                    disabled={aiDetectLoading}
                                    className={styles.buttons__centeringMod}
                                    icon={<StickIcon />}
                                    colorScheme="orange"
                                >
                                    {t(`humanizer.rehumanize`)}
                                </ButtonPrimary>
                            </>
                        )}
                    </div>
                    <div>
                        {aiReDetectShow && (
                            <TextDetectionResult
                                data={reDetectAi}
                                bottomAiResultBadge={false}
                                loading={reDetectLoading}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
