import {
    AxiosError,
    InternalAxiosRequestConfig,
    ParamsSerializerOptions,
} from 'axios'
import qs from 'qs'
import { Api, HttpClient } from './Api'

const injectAuthorization = (
    config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
    try {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
        return config
    } catch (error) {
        window.location.pathname = '/auth'
        throw new Error('User is not specified')
    }
}

const responseIntercept = async (error: AxiosError) => {
    const { response, config } = error
    if (response && response.status === 401) {
        try {
            let refreshToken = localStorage.getItem('refresh')

            if (!refreshToken) {
                return
            }
            return $api.api
                .authControllerRefresh({ refreshToken })
                .then((res) => {
                    if (res.data.data) {
                        localStorage.setItem(
                            'token',
                            res.data.data?.accessToken
                        )
                        localStorage.setItem(
                            'refresh',
                            res.data.data?.refreshToken
                        )
                        window.location.reload()
                    }
                })
                .catch((error) => {
                    localStorage.removeItem('refresh')
                    localStorage.removeItem('token')
                    window.location.pathname = '/auth'
                })
        } catch (error) {
            window.location.pathname = '/auth'
            return Promise.reject(error)
        }
    }
    return Promise.reject(error)
}

const paramsSerializer = (
    params: Record<string, any>,
    options?: ParamsSerializerOptions
): string => {
    return qs.stringify(params)
}

const $api = new Api(
    new HttpClient({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        paramsSerializer: paramsSerializer,
    })
)

$api.http.instance.interceptors.request.use(injectAuthorization, (error) =>
    Promise.reject(error)
)
$api.http.instance.interceptors.response.use(
    (responce) => responce,
    responseIntercept
)

export default $api
