import { toast } from 'react-toastify'

export const makeToast = (
    type: 'success' | 'error' | 'warn',
    message: string
) => {
    if (type === 'success') return toast.success(message)
    if (type === 'warn') return toast.warn(message)
    return toast.error(message)
}
