import React from "react";
import { Header, Footer } from "@components";
import { PricingBlock, ChooseUsSection, StayInShadowBlock } from "@pages";

export const Pricing: React.FC = () => {
  

  
  return (
    <>
      <Header />
      <PricingBlock />
      <ChooseUsSection />
      <StayInShadowBlock />
      <Footer />
    </>
  );
};
