import React from 'react'
import styles from './style.module.scss'

const Pagination = ({ perPage, length, active, setCurrentTab }: any) => {
    const paginationNumbers = []

    for (let i = 1; i <= Math.ceil(perPage / length); i++) {
        paginationNumbers.push(i)
    }

    return (
        <div className={styles.Navigation}>
            {paginationNumbers.map((pageNumber) => (
                <div
                    key={pageNumber}
                    onClick={() => setCurrentTab(pageNumber)}
                    className={
                        active === pageNumber
                            ? styles.Navigation__button +
                              ' ' +
                              styles.Navigation__button_selected
                            : styles.Navigation__button
                    }
                >
                    {pageNumber}
                </div>
            ))}
        </div>
    )
}

export default Pagination
