import { createEffect, createEvent, createStore, sample } from 'effector'
import { getBillingsQuery } from './query'
import { PaginatedDocumented } from '../../http/Api'

export const billingsFx = createEffect(getBillingsQuery)
export const $billings = createStore<any>([]).on(
    billingsFx.doneData,
    (_store, res) => res
)
export const billingsEvent = createEvent<any>()

sample({
    target: billingsEvent,
    fn: (clk: any) => clk,
    clock: billingsFx.doneData,
})
