import { createEffect, createEvent, createStore, sample } from 'effector'
import {
    getPricingQuery,
    getSubscriptionAccountQuery,
    getSubscriptionQuery,
} from './query'
import { PaymentAccountResponseDto } from '../../http/Api'

export const pricingFx = createEffect(getPricingQuery)
export const $pricing = createStore<any>([]).on(
    pricingFx.doneData,
    (_store, res) => res
)
export const pricingEvent = createEvent()

sample({
    target: pricingEvent,
    fn: (clk: any) => clk,
    clock: pricingFx.doneData,
})

export const subscribeFx = createEffect(getSubscriptionQuery)
export const $subscribe = createStore<any>(null).on(
    subscribeFx.doneData,
    (_store, res) => res
)
export const subscribeEvent = createEvent<any>('')

sample({
    target: subscribeEvent,
    fn: (clk: any) => clk,
    clock: subscribeFx.doneData,
})

export const accountSubFx = createEffect(getSubscriptionAccountQuery)
export const $accountSub = createStore<any>({}).on(
    accountSubFx.doneData,
    (_store, res) => res
)
export const accountSubEvent = createEvent<any>()

sample({
    fn: (clk: any) => clk,
    target: accountSubEvent,
    clock: accountSubFx.doneData,
})
