import React from "react";
import styles from "./style.module.scss";

type Props = {
  text: string;
  icon: any;
  withBackground: boolean;
  onClick?: () => void;
};

export const Badge: React.FC<Props> = ({
  text,
  icon,
  withBackground,
  onClick = () => undefined,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.Badge} ${
        withBackground ? styles.Badge_withBackground : ""
      }`}
    >
      <small className={styles.Badge__text}>{text}</small>
      {icon}
    </div>
  );
};
