import React from "react";
import { Header, Footer } from "@components";
import { FAQBlock } from "@pages";
import { SendEmailBlock } from "./components";

export const Support: React.FC = () => {
  return (
    <>
      <Header />
      <SendEmailBlock />
      <FAQBlock />
      <Footer />
    </>
  );
};
