import { createEvent, createStore, sample } from "effector";

export const $dropDownShow = createStore<boolean>(false)
export const dropDownHandler = createEvent<boolean>()

sample({
    clock: dropDownHandler,
    target: $dropDownShow
})
export const $languageChose = createStore<boolean>(false)
export const languageChoseHandler = createEvent<boolean>()

sample({
    clock: languageChoseHandler,
    target: $languageChose
})