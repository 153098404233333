import React from 'react'
import styles from './style.module.scss'
import { ButtonPrimary } from '@components'
import { StepDescription } from './components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { SWIPER_DATA } from './constants'

import { ReactComponent as StickIcon } from '@assets/icons/Stick.svg'
import { ReactComponent as AiIcon } from '@assets/icons/AI.svg'
import { ReactComponent as GreenButtonIcon } from './assets/GreenButton.svg'
import { ReactComponent as Step1Icon } from './assets/Step1.svg'
import { ReactComponent as Step2Icon } from './assets/Step2.svg'
import { ReactComponent as BlockArrow1 } from './assets/BlockArrow1.svg'
import { ReactComponent as BlockArrow2 } from './assets/BlockArrow2.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const ShadowGPTReview: React.FC = () => {
    const navigate = useNavigate()

    const { t } = useTranslation()

    return (
        <div className={styles.ShadowGPTReview}>
            <div
                className={`app-container ${styles.ShadowGPTReview__topContent}`}
            >
                <h2 className={styles.ShadowGPTReview__title}>
                    {t('humanizer.work.title')}
                </h2>
                <p className={styles.ShadowGPTReview__caption}>
                    {t('humanizer.work.desc')}
                </p>
                <div className={styles.ShadowGPTReview__marginWrapper}>
                    <StepDescription
                        content={
                            <>
                                <Step1Icon className={styles.Content__icon} />
                                <ButtonPrimary
                                    className={styles.Content__button}
                                    icon={<StickIcon />}
                                    colorScheme="orange"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    {t('humanizer.work.userBtn')}
                                </ButtonPrimary>
                            </>
                        }
                        stepNum={1}
                        title={t('humanizer.work.userInput')}
                        description={t('humanizer.work.userText')}
                    />
                    <BlockArrow1
                        className={styles.ShadowGPTReview__blockIcon}
                    />
                    <StepDescription
                        content={
                            <>
                                <Step2Icon className={styles.Content__icon} />
                                <ButtonPrimary
                                    className={styles.Content__button}
                                    icon={<AiIcon />}
                                    colorScheme="dark-orange"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    {t('humanizer.work.scanBtn')}
                                </ButtonPrimary>
                            </>
                        }
                        reversed={true}
                        stepNum={2}
                        title={t('humanizer.work.scanTitle')}
                        description={t('humanizer.work.scanText')}
                    />
                    <BlockArrow2
                        className={styles.ShadowGPTReview__blockIcon}
                    />
                    <StepDescription
                        content={
                            <div className={styles.thirdBlock}>
                                <p className={styles.thirdBlock__caption}>
                                    {t('humanizer.detect.text1')}
                                </p>
                                <GreenButtonIcon />
                                <p className={styles.thirdBlock__caption}>
                                    {t('humanizer.detect.text2')}
                                </p>
                            </div>
                        }
                        stepNum={3}
                        title={t('humanizer.detect.title')}
                        description={t('humanizer.detect.text')}
                    />
                    <BlockArrow1
                        className={styles.ShadowGPTReview__blockIcon}
                    />
                    <StepDescription
                        content={
                            <>
                                <Step2Icon className={styles.Content__icon} />
                                <ButtonPrimary
                                    className={styles.Content__button}
                                    icon={<StickIcon />}
                                    colorScheme="orange"
                                    onClick={() =>
                                        navigate('/generation/history')
                                    }
                                >
                                    {t('humanizer.history.btn')}
                                </ButtonPrimary>
                            </>
                        }
                        stepNum={4}
                        reversed={true}
                        title={t('humanizer.history.title')}
                        description={t('humanizer.history.text')}
                    />
                </div>
            </div>
            <div className={styles.ShadowGPTReview__background}>
                <div className="app-container">
                    <div className={styles.ShadowGPTReview__bottomContent}>
                        <div className={styles.ShadowGPTReview__botttomText}>
                            <h2>{t('humanizer.experience.title')}</h2>
                            <p>{t('humanizer.experience.text')}</p>
                            <ButtonPrimary
                                className={
                                    styles.ShadowGPTReview__botttomText_button
                                }
                                colorScheme="orange"
                                onClick={() => navigate('/auth')}
                            >
                                {t('humanizer.experience.btn')}
                            </ButtonPrimary>
                        </div>
                        <div>
                            <Swiper
                                className={
                                    styles.ShadowGPTReview__buttonsContainer
                                }
                                centeredSlides={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                direction={'vertical'}
                                spaceBetween={0}
                                slidesPerView={3}
                                loop={true}
                                autoplay={{
                                    delay: 1900,
                                }}
                            >
                                {SWIPER_DATA.map(({ title, icon: Icon }, i) => {
                                    return (
                                        <SwiperSlide
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {({ isActive }) => (
                                                <div
                                                    className={`${
                                                        styles.ShadowGPTReview__grayButton
                                                    } ${
                                                        isActive
                                                            ? styles.ShadowGPTReview__darkButton
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`${
                                                            styles.ShadowGPTReview__btnIcon
                                                        } ${
                                                            isActive
                                                                ? styles.ShadowGPTReview__btnIcon_active
                                                                : ''
                                                        }`}
                                                    >
                                                        <Icon />
                                                    </div>
                                                    <span
                                                        className={`${
                                                            styles.ShadowGPTReview__grayButton_text
                                                        } ${
                                                            isActive
                                                                ? styles.ShadowGPTReview__darkButton_text
                                                                : ''
                                                        }`}
                                                    >
                                                        {title}
                                                    </span>
                                                </div>
                                            )}
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
