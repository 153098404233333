import { createEvent, createStore, sample } from 'effector'

export const $email = createStore<string>("")
export const emailHandler = createEvent<string>()

sample({
    clock: emailHandler,
    target: $email
})

export const $verificationCode = createStore<string>('')
export const verificationCodeHandler = createEvent<string>()

sample({
    clock: verificationCodeHandler,
    target: $verificationCode,
})

export const $isReqPassword = createStore<boolean>(false)
export const reqPasswordHandler = createEvent<boolean>()

sample({
    target: $isReqPassword,
    clock: reqPasswordHandler,
})

export const $retryRequestPassword = createStore<boolean>(false)
export const retryRequestHandler = createEvent<boolean>()

sample({
    target: $retryRequestPassword,
    clock: retryRequestHandler,
})

export const $isNumberLogin = createStore<boolean>(false)
export const isNumberLoginEvent = createEvent<boolean>()

sample({
    target: $isNumberLogin,
    clock: isNumberLoginEvent
})

export const $phoneInput = createStore<any>("")
export const phoneInputHandler = createEvent()
sample({
    clock: phoneInputHandler,
    target: $phoneInput
})