import React from 'react'
import styles from './style.module.scss'

type Props = {
    placeholder?: string
    label: string
    disabled?: boolean
    onChange?: (evt: any) => void
    value: string
}

export const Input: React.FC<Props> = ({
    placeholder,
    label,
    disabled,
    value,
    onChange
}) => {
    return (
        <div>
            <small className={styles.Input__label}>{label}</small>
            <input
                maxLength={34}
                minLength={2}
                className={styles.Input__input}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}
