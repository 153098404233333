import React, { useState, useRef, useEffect } from 'react'
import styles from '../../style.module.scss'
import {
    CopyElement,
    Badge,
    TextDetectionResult,
    ConfirmationPopover,
} from '@components'

import avatar from '@assets/png/AvatarHistory.png'
import shadowGPT from '@assets/png/ShadowGPT.png'

import { ReactComponent as CommentIcon } from '../../assets/svg/Comment.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/Delete.svg'
import { ReactComponent as InfoIcon } from '../../assets/svg/Info.svg'
import { ReactComponent as StarsIcon } from '../../assets/svg/Stars.svg'
import { convertTimeToHumanReadable } from '../../../../../../lib/covertDate'
import { useNavigate } from 'react-router-dom'
import {
    $chatList,
    activeChatEvent,
    chatInputHandler,
    chatListFx,
} from '../../../../../../features/chat/model'
import { useUnit } from 'effector-react'
import $api from '../../../../../../http'
import { humanizerInputHandler } from '../../../../../../features/humanizer/model'
import { deleteHistoryFx } from '../../../../../../features/history/model'
import { useTranslation } from 'react-i18next'
import { makeToast } from '../../../../../../shared/ui/toast'
import { $user, $userLanguage } from '../../../../../../features/app/model'

function countWords(str: string) {
    str = str.trim()
    var words = str.split(/\s+/)
    return words.length
}

type Props = {
    title: string
    date: string
    // wordsCount: string
    text: string
    key: string
    request: string
    chatId: string | null
    requestId: string
    requestNumber: any
}

export const HistoryItem: React.FC<Props> = ({
    requestId,
    key,
    text,
    date,
    chatId,
    request,
    requestNumber,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [chatList, getChats] = useUnit([$chatList, chatListFx])
    const currLang = useUnit($userLanguage)

    const user = useUnit($user)
    const [isDeleted, setIsDeleted] = useState(false)
    const [modalOpened, setModalOpened] = useState(false)
    const [opened, setOpened] = useState(false)
    const [openedTEST, setTESTOpened] = useState(false)
    const [containerMaxHeight, setContainerMaxHeight] = useState('auto')
    const detailsTitle = !opened
        ? t('history.details')
        : t('history.closeDetails')
    const textContainerRef: any = useRef(null)

    const openDetailesButtonClickHandler = () => {
        const checkForTooLong = text.length < user.subscription.maxWords
        const checkForTooShort = text.length > 2

        if (checkForTooLong) {
            if (checkForTooShort) {
                setTESTOpened(!openedTEST)
                setOpened(!opened)
            } else {
                makeToast('error', t('history.toast.tooShort'))
                setOpened(false)
            }
        } else {
            makeToast('error', t('humanizer.toast.tooLong'))
        }
    }

    useEffect(() => {
        if (textContainerRef.current) {
            setContainerMaxHeight(
                `${textContainerRef.current.getBoundingClientRect().height}px`
            )
        }
    }, [textContainerRef])

    const redirectToChatWithText = (id: string | null, request: string) => {
        getChats()
        chatInputHandler(request)
        activeChatEvent(chatList.find((el: any) => el.id === id))
        navigate('/generation/shadow-chat')
    }

    const redirectToChat = (id: string | null) => {
        getChats()
        activeChatEvent(chatList.find((el: any) => el.id === id))
        navigate('/generation/shadow-chat')
    }

    const redirectToHumanizer = (request: string) => {
        humanizerInputHandler(request)
        navigate('/')
    }

    return (
        <>
            {!isDeleted && (
                <div key={key}>
                    {modalOpened && (
                        <ConfirmationPopover
                            close={setModalOpened}
                            deleteHandler={() => {
                                deleteHistoryFx(requestId)
                                setIsDeleted(true)
                                setModalOpened(!modalOpened)
                            }}
                        />
                    )}
                    <div className={styles.Content__textWrapper}>
                        <div className={styles.Content__indicatorWrapper}>
                            <div
                                className={`${styles.Content__indicator} ${
                                    chatId !== null
                                        ? styles.Content__indicator_red
                                        : ''
                                }`}
                            />
                            <small>
                                {chatId !== null ? 'ShadowChat' : 'Humanizer'}
                            </small>
                        </div>
                        <small className={styles.Content__time}>
                            {convertTimeToHumanReadable(date, currLang)}
                        </small>
                        <small>
                            {chatId !== null
                                ? requestNumber + ` ${t('chat.requests')}`
                                : countWords(text) + ` ${t('history.words')}`}
                        </small>
                    </div>
                    <div
                        onTransitionEnd={() => {
                            if (!opened && openedTEST) {
                                setTESTOpened(false)
                            }
                        }}
                        style={{
                            maxHeight: containerMaxHeight,
                        }}
                        ref={textContainerRef}
                        className={`${styles.Content__text} ${
                            opened ? styles.Content__text_animated : ''
                        }`}
                    >
                        {openedTEST ? (
                            <div
                                className={`${
                                    opened ? styles.fadeInOut : styles.hidden
                                }`}
                            >
                                <ChatMessage
                                    requestText={request}
                                    responseText={text}
                                />
                            </div>
                        ) : (
                            <div
                                className={`${
                                    !opened ? styles.fadeInOut1 : styles.hidden2
                                }`}
                            >
                                <p className={styles.Content__text_main}>
                                    {text}
                                </p>
                                <CopyElement
                                    text={'Copy'}
                                    onClick={() =>
                                        navigator.clipboard.writeText(text)
                                    }
                                    className={styles.Content__margin0}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles.Content__badgeContainer}>
                        {chatId !== null ? (
                            <>
                                <Badge
                                    text={t('history.to')}
                                    withBackground={true}
                                    icon={<CommentIcon />}
                                    onClick={() => redirectToChat(chatId)}
                                />
                                <Badge
                                    text={t('humanizer.review.reGenerate')}
                                    withBackground={true}
                                    icon={<StarsIcon />}
                                    onClick={() =>
                                        redirectToChatWithText(chatId, request)
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Badge
                                    text={t('humanizer.review.reHumanizer')}
                                    withBackground={true}
                                    icon={<StarsIcon />}
                                    onClick={() => redirectToHumanizer(request)}
                                />
                            </>
                        )}

                        <Badge
                            onClick={openDetailesButtonClickHandler}
                            text={detailsTitle}
                            withBackground={false}
                            icon={<InfoIcon />}
                        />
                        <Badge
                            onClick={() => setModalOpened(!modalOpened)}
                            text={t('chat.delete')}
                            withBackground={false}
                            icon={<DeleteIcon />}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
const ChatMessage = (props: any) => {
    const user = useUnit($user)

    const [isLoading, setIsLoading] = useState(false)
    const [detectResult, setDetectResult] = useState<any>([])
    const detectAi = () => {
        setIsLoading(true)
        $api.api
            .textsPublicControllerGetUniquenessPercentages({
                text: props.responseText,
            })
            .then((res) => {
                setIsLoading(false)
                setDetectResult(res.data.data?.responses)
            })
    }

    useEffect(() => {
        detectAi()
    }, [])

    const { t } = useTranslation()

    return (
        <div>
            <div className={styles.ChatMessage__item}>
                <div className={styles.Content__nickContainer}>
                    <img src={avatar} alt="" />
                    <p className={styles.Content__nick}>{t('history.you')}</p>
                </div>
                <p className={styles.Content__text_main}>{props.requestText}</p>
                <div className={styles.Content__copyContainer}>
                    <CopyElement className={styles.Content__copyItem} />
                    <small className={styles.Content__wordsCount}>
                        {props.responseText
                            ? countWords(props.responseText)
                            : null}{' '}
                        / {user.subscription.maxWords} {t('history.words')}
                    </small>
                </div>
            </div>
            <div>
                <div className={styles.Content__nickContainer}>
                    <img src={shadowGPT} alt="" />
                    <p className={styles.Content__nick}>ShadowGPT</p>
                </div>
                <p className={styles.Content__text_main}>
                    {props.responseText}
                </p>
                <TextDetectionResult loading={isLoading} data={detectResult} />
            </div>
        </div>
    )
}
