import React from 'react'
import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'

type Props = {
    stepNum: number
    title: string
    description: string
    reversed?: boolean
    content: any
}

export const StepDescription: React.FC<Props> = ({
    stepNum,
    title,
    description,
    content,
    reversed = false,
}) => {
    const { t } = useTranslation()

    return (
        <div
            className={`${styles.StepDescription} ${
                reversed ? styles.StepDescription_reversed : ''
            }`}
        >
            <div className={styles.StepDescription__content}>{content}</div>
            <div className={styles.StepDescription__textContainer}>
                <small className={styles.step}>
                    {t('humanizer.step')} {stepNum}
                </small>
                <p className={styles.title}>{title}</p>
                <p className={styles.description}>{description}</p>
            </div>
        </div>
    )
}
