import $api from '../../http'

type GetPricingType = {
    languageCode: string
    currencyCode?: 'SEK' | 'USD' | 'EUR'
}
export const getPricingQuery = async (payload: GetPricingType) => {
    try {
        const res =
            await $api.api.subscriptionsPublicControllerGetSubscriptions(
                payload
            )
        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

export const getSubscriptionQuery = async (payload: any) => {
    try {
        const res = await $api.api.subscriptionsControllerCreateSubscription({
            isRegularPayment: true,
            paymentService: 'revolut',
            subscriptionId: payload,
        })

        return res.data.data
    } catch (error) {
        console.log(error)
    }
}

export const getSubscriptionAccountQuery = async () => {
    try {
        const res = await $api.api.paymentsControllerGetAccount()

        return res.data.data
    } catch (error) {
        console.log(error)
    }
}
