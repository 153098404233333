import { ReactComponent as StarsIcon } from "../assets/Stars.svg";
import { ReactComponent as StickIcon } from "../assets/Stick.svg";
import { ReactComponent as CrownIcon } from "../assets/Crown.svg";
import { ReactComponent as MoneyIcon } from "../assets/Money.svg";
import { ReactComponent as MessagesIcon } from "../assets/Messages.svg";
import { ReactComponent as TimeCycleIcon } from "../assets/TimeCycle.svg";
import { ReactComponent as OutIcon } from "../assets/Out.svg";

export const SIDE_MENU_DATA = [
    [
        {
            icon: StickIcon,
            path: "/",
            title: "Himanizer"
        },
        {
            icon: StarsIcon,
            path: "/generation/shadow-chat",
            title: "ShadowChat"
        },
        {
            icon: CrownIcon,
            path: "/pricing",
            title: "Pricing"
        },
    ],
    [
        {
            icon: MoneyIcon,
            path: "/generation/billings",
            title: "Billings"
        },
        {
            icon: TimeCycleIcon,
            path: "/generation/history",
            title: "History"
        },
        {
            icon: MessagesIcon,
            path: "/support",
            title: "Support"
        },
      
    ]
];