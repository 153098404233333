import React from "react";
import styles from "../../style.module.scss";

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className={`${styles.TermsAndPrivicyPages} app-container`}>
      <h2>Privacy Policy</h2>
      <div>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            ShadowGPT (“Service,” “we,” or “us”) is committed to protecting your
            personal information and being transparent about its usage. This
            Privacy Policy applies when you use our AI platform and associated
            services, including our website and apps (collectively called our
            “Platform”). This policy should be read along with our Terms of Use,
            and by using our Platform, you agree to their terms. We may update
            this Privacy Policy occasionally, so revisit it periodically. If you
            disagree with this Privacy Policy's terms, please refrain from using
            our Platform.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>
          Information We Collect
        </h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            • Personal Information: Such as your name, email address, and other
            details that you provide.
          </p>
          <p>
            • Usage Information: Details regarding your Platform use, including
            actions taken, content generated, date, time of your visit, pages
            accessed.
          </p>
          <p>
            • Device Information: About the device used to access our Platform,
            such as type, Operating System, settings.
          </p>
          <p>
            {" "}
            • Non-personal Information: Such as your IP address, etc., collected
            directly from you, automatically as you navigate, or from
            third-party sources like social media platforms and analytics
            providers.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>
          Use of Information
        </h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            Your information is processed to serve numerous purposes such as:
          </p>
          <p>
            • Improving Platform and Services: Refined services and user
            experience, conducting research and analysis.
          </p>
          <p>
            • Communication: Responding to inquiries, providing information
            about modifications in our services.
          </p>
          <p>
            • Legal Obligations: Complying with legal requirements and policies,
            and protecting our rights.
          </p>
          <p>
            We may share your information with contracted third-party service
            providers who assist us. Selling your data without consent will
            never occur.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>
          Disclosure of Information
        </h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            We may disclose your personal information to third-party service
            providers who perform services on our behalf, such as website
            hosting, data analysis, and customer support. We may also disclose
            your personal information to comply with our legal obligations,
            enforce our Terms of Use, or protect our rights, property, or safety
            or the rights, property, or safety of others.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>Cookies</h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            We may use cookies to collect information about your use of our
            website. Cookies are small files that are stored on your device when
            you visit our website. We may use cookies to personalize your
            experience on our website, analyze your use of our website, and
            improve our website and its services. You may refuse to accept
            cookies by adjusting your browser settings, but doing so may affect
            your ability to  website and its services.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>Your rights</h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            You have the right to access, correct, and delete your personal
            information, as well as the right to object to the processing of
            your information. You also have the right to data portability, which
            allows you to request that we transfer your information to another
            service provider.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>
          Third-Party Links
        </h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            Our website may contain links to third-party websites or services
            that are not owned or controlled by us. We are not responsible for
            the privacy practices or content of these third-party websites or
            services. We encourage you to read the privacy policies of these
            third-party websites or services before providing any personal
            information.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>Security</h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            We take steps to protect your information from unauthorized access,
            use, or disclosure. We retain your information for as long as
            necessary to provide you with our services and to comply with our
            legal obligations.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>
          Children's Privacy
        </h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            Our website is not intended for children under the age of 13. We do
            not knowingly collect personal information from children under the
            age of 13. If you are a parent or guardian and believe that your
            child has provided us with personal information, please contact us
            at support@shadowgpt.ai to have the information removed.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>
          Changes to Privacy Policy
        </h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            We reserve the right to modify or update this Privacy Policy at any
            time without prior notice. Your continued use of our website after
            such modifications shall constitute your acceptance of the modified
            Privacy Policy.
          </p>
        </div>
      </div>
      <div>
        <h3 className={styles.TermsAndPrivicyPages__title}>Contact Us</h3>
        <div className={styles.TermsAndPrivicyPages__subTitleContainer}>
          <p>
            If you have any questions about these Terms or our website, please
            contact us a support@shadowgpt.ai
          </p>
        </div>
      </div>
    </div>
  );
};
